import {fetcher} from "@framework/fetcher";

const getDeliveryCountries = async (locale: string) => {
    return await fetcher({
        locale,
        url: `/countries`,
        method: 'GET',
    });
}
export default getDeliveryCountries
