import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const getVoucher = async (orderId: string, locale: string) => {
    return await fetcher({
        url: `/gift-vouchers/${orderId}?lang=${locale}`,
        method: 'GET',
    });
}
export default getVoucher
