import Link from 'next/link'
import { useRouter} from "next/router"
import i18nConfig from '../../../i18n.json';
import useTranslation from 'next-translate/useTranslation';
import getTranslateUrl from "@framework/api/endpoints/getTranslateUrl";


export default function Langbar(props: any) {
    const router = useRouter()
    const { locales } = i18nConfig;
    const { t, lang } = useTranslation('common');
    const url = router.asPath.split("/")
          url.shift();

    const fixPages: any = {
        'zodpovedna-vyroba': 'responsible-production',
        'responsible-production': 'zodpovedna-vyroba',
    }

    const handleChangeLang = async (lang: string) => {
        if (fixPages[url[1]]) {
            url[1] = fixPages[url[1]];
        } else if((url[0] == 'category' && url[1] === undefined)) {
            url[0] = 'category';
        } else if (url[0] == 'page' || url[0] == 'category' || url[0] == 'product') {
            const res = await getTranslateUrl(`${router.query.name}`, router.locale!, lang, url[0])
            url[1] = res.url;
        }
        if(router.query.order !== undefined && router.query.sort !== undefined) {
            await router.push(`/${url.join('/')}?sort=${router.query.sort}&order=${router.query.order}`, undefined, { locale: lang });
        } else {
            await router.push(`/${url.join('/')}`, undefined, { locale: lang });
        }
    }

    return (
        <div className="header-lang"> <span>{lang.toLocaleUpperCase()}</span>
            <div className="header-lang-dropdown">
                {locales.map(lng => {
                    if(lng !== lang) {
                        {/*url.join("/")  + "/" + props.slugs[lng] <Link key={lng} href={"/"} locale={lng}>*/}
                        return (<a href={"#"} key={lng} onClick={(e) => { e.preventDefault(); handleChangeLang(lng) }}>{lng.toLocaleUpperCase()}</a>)
                    }
                })}
            </div>
        </div>
    );
}
