import style from './loader.module.css';
import cn from 'classnames';

export default function Loader({...props}) {


    return <>
        <div className={style.ldsRing} style={{width: props.size, height: props.size}}>
            <div className={cn(style.ldsRingDiv, {
                [style.ldsRingDivWhite]: props.color == 'white',
                [style.ldsRingDivDefault]: props.color != 'white'
            })} style={{width: props.size, height: props.size}} />
            <div className={cn(style.ldsRingDiv, {
                [style.ldsRingDivWhite]: props.color == 'white',
                [style.ldsRingDivDefault]: props.color != 'white'
            })} style={{width: props.size, height: props.size}} />
            <div className={cn(style.ldsRingDiv, {
                [style.ldsRingDivWhite]: props.color == 'white',
                [style.ldsRingDivDefault]: props.color != 'white'
            })} style={{width: props.size, height: props.size}} />
            <div className={cn(style.ldsRingDiv, {
                [style.ldsRingDivWhite]: props.color == 'white',
                [style.ldsRingDivDefault]: props.color != 'white'
            })} style={{width: props.size, height: props.size}} />
        </div>
    </>;
}
