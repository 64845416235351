import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const CreateReservation = async (body: any, locale: string) => {
    // const customerToken = Cookies.get(CUSTOMER_COOKIE); //auth ? auth : Cookies.get(CUSTOMER_COOKIE);

    return await fetcher({
        body,
        url: `/reservations?lang=${locale}`,
        method: 'POST',
    });
}
export default CreateReservation
