import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const getFavorites = async (locale: string) => {
    const token = Cookies.get(CUSTOMER_COOKIE)
    return await fetcher({
        url: `/my-account/favorites?lang=${locale}`,
        method: 'GET',
        headers: {
            'authorization': 'bearer ' + token
        }
    });
}
export default getFavorites
