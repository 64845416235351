import {fetcher} from "@framework/fetcher";

const updateMyPhoto = async (token: string, image: string) => {
    return await fetcher({
        url: `/customers/me/photo`,
        method: 'POST',
        body: {
            image
        },
        headers: {
            'authorization': 'bearer ' + token
        }
    });
}
export default updateMyPhoto
