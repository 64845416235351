import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const getHomepage = async (locale: string | undefined, auth?: string) => {
    const customerToken = auth ? auth : Cookies.get(CUSTOMER_COOKIE);
    return await fetcher({
        locale,
        headers: {
            'Authorization': 'Bearer ' + customerToken,
        },
        url: `/products/homepage`,
    });
}
export default getHomepage
