import React, { FC, useEffect, useState, useCallback } from 'react'
import {useForm, SubmitHandler, UnpackNestedValue} from "react-hook-form";
import {Button, useUI} from '@components/ui'
import useLogin from '@framework/auth/use-login'
import Link from 'next/link'
import {useRouter} from "next/router";
import FlashMessage from "@components/common/FlashMessages";
import {FlashMessageProps} from "@components/common/FlashMessages/FlashMessage";
import useTranslation from "next-translate/useTranslation";
import cn from 'classnames';
import forgottenPassword from "@framework/api/endpoints/forgottenPassword";

type LoginForm = {
    email: string,
    password: string,
};

type ForgottenPasswordForm = {
    email: string,
};


const LoginView = () => {

    const {t} = useTranslation("common")
    const login = useLogin()
    const { setSidebarView, closeSidebar } = useUI()
    const router = useRouter()

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<FlashMessageProps>({message: "", type: 'info'})
    const [showForgottenPassword, setShowForgottenPassword] = useState<boolean>(false);

    const forgottenPasswordForm = useForm<ForgottenPasswordForm>();
    const { register, handleSubmit, watch, formState: { errors } } = useForm<LoginForm>();

    useEffect(() => {
        setMessage({ message: '', type: 'info'});
    }, [showForgottenPassword]);

    const onSubmit: SubmitHandler<LoginForm> = async (data: UnpackNestedValue<LoginForm>) => {
        setLoading(true);
        try {
            setLoading(true)
            setMessage({ message: '', type: 'info'})
            await login(data);
            setLoading(false)
            closeSidebar()
        } catch ({errors}) {
            setLoading(false);
            // @ts-ignore
            setMessage({message: t("Zadali jste chybné přihlašovací údaje"), type: 'danger'});
        }
    };

    const onSubmitForgottenForm: SubmitHandler<ForgottenPasswordForm> = async (data: UnpackNestedValue<ForgottenPasswordForm>) => {
        setLoading(true);
        try {
            setLoading(true)
            setMessage({ message: '', type: 'info'})
            await forgottenPassword(data.email);
            setLoading(false)
            setMessage({ message: t('forgottenPasswordSuccess'), type: 'success'})
        } catch ({errors}) {
            setLoading(false);
            // @ts-ignore
            setMessage({message: t("forgottenPasswordNotFoundEmail"), type: 'danger'});
        }
    };



    if (showForgottenPassword) {
        return (
            <>
                <h2>{t("Zapomenuté heslo")}</h2>
                <FlashMessage message={message.message} type={message.type} />
                <form onSubmit={forgottenPasswordForm.handleSubmit(onSubmitForgottenForm)} className={"login-form"}>
                    <div className="form-wide">
                        <label>{t("Váš e-mail")}</label>
                        <input type="text" {...forgottenPasswordForm.register("email", { required: true })} className={cn({"error-input": forgottenPasswordForm.formState.errors.email})} />
                        {forgottenPasswordForm.formState.errors.email && <span className="error-item">{t("Email je povinný")}</span>}
                    </div>
                    <div className="form-wide">
                        <a href="#" onClick={() => setShowForgottenPassword(false)} className="forgot-password">{t("Přihlásit se")}</a>
                    </div>
                    <div className="form-wide">
                        <Button
                            className={"btn btn-background"}
                            type="submit"
                            loading={loading}
                            disabled={loading}
                        >
                            {t("Obnovit heslo")}
                        </Button>
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <h2>{t("Můj účet")}</h2>
            <FlashMessage message={message.message} type={message.type} />
            <form onSubmit={handleSubmit(onSubmit)} className={"login-form"}>
                <div className="form-wide">
                    <label>{t("Vaše uživatelské jméno")}</label>
                    <input type="text" {...register("email", { required: true })} className={cn({"error-input": errors.email})} />
                    {errors.email && <span className="error-item">{t("Email je povinný")}</span>}
                </div>
                <div className="form-wide">
                    <label>{t("Vaše heslo")}</label>
                    <input type="password" {...register("password", { required: true })} className={cn({"error-input": errors.password})} />
                    {errors.password && <span className="error-item">{t("Heslo je povinné")}</span>}
                </div>
                <div className="form-wide">
                    <a href="#" onClick={() => setShowForgottenPassword(true)} className="forgot-password">{t("Zapomněli jste heslo?")}</a>
                </div>
                <div className="form-wide">
                    <Link href={"/sign/up"} locale={router.locale}>
                        <a className="btn" onClick={closeSidebar}>{t("Vytvořit účet")}</a>
                    </Link>
                    <Button
                        className={"btn btn-background"}
                        type="submit"
                        loading={loading}
                        disabled={loading}
                    >
                        {t("Přihlásit se")}
                    </Button>
                </div>
            </form>
        </>
    )
}

export default LoginView
