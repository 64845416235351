import React, {FC, useEffect, useState, useCallback, useRef} from 'react'
import {useRouter} from "next/router";
import { Range } from 'rc-slider';
import storevio from "@lib/api";
import {useUI} from "@components/ui";
import {formatPrice} from "@lib/formatPrice";
import useTranslation from "next-translate/useTranslation";
import getStocks from "@framework/api/endpoints/getStocks";


const Filter = () => {
    const { t } = useTranslation('common');
    const router = useRouter()
    const [paramFilter, setParamFilter] = useState<any[]>([]);
    const [variants, setVariants] = useState<any[]>([]);
    const [checkedParameters, setCheckedParameters] = useState<any[]>([]);
    const [checkedVariants, setCheckedVariants] = useState<any[]>([]);
    const [checkedBranchOffice, setCheckedBranchOffice] = useState<any[]>([]);
    const [checkedTag, setCheckedTag] = useState<any[]>([]);
    const [rangePrice, setRangePrice] = useState([0, 10000])
    const [branchOffices, setBranchOffices] = useState([])
    const { closeSidebar, filterPrice } = useUI()
    const [ settings, setSettings ] = useState<any>(null);
    let rangeElement = useRef<any>();

    useEffect(() => {
        setRangePrice([filterPrice.min, filterPrice.max]);
    }, []);

    useEffect(() => {
        async function fetchData() {
           setParamFilter(await storevio.parameterFilters(router.locale))
           setVariants(await storevio.getFilterVariants(router.locale))
           setSettings(await storevio.getSettings(router.locale!));
           setBranchOffices(await getStocks());
        }

        if(Array.isArray(router.query.parameter)) {
            setCheckedParameters(router.query.parameter);
        } else {
            setCheckedParameters([router.query.parameter])
        }

        if(Array.isArray(router.query.variant)) {
            setCheckedVariants(router.query.variant);
        } else {
            setCheckedVariants([router.query.variant])
        }

        if(Array.isArray(router.query.branchOffice)) {
            setCheckedBranchOffice(router.query.branchOffice);
        } else {
            setCheckedBranchOffice([router.query.branchOffice])
        }

        setRangePrice([
            (router.query.priceFrom ? router.query.priceFrom : filterPrice.min) as number,
            (router.query.priceTo ? router.query.priceTo : filterPrice.max) as number,
        ])

        fetchData()
    }, [router.locale, router.query])

    const handleSliderChange = (value: any) => {
        setRangePrice(value)
    }

    const handleSliderAfterChange = (value: any) => {

        router.query.priceFrom = value[0]
        router.query.priceTo = value[1]
        // @ts-ignore
        //this.element.blur();
        router.push(router);
    }

    const handleFilter = (e: any) => {
        let parameter = [...checkedParameters];

        if(e.target.checked) {
            parameter.push(e.target.value);
        } else {
            parameter = parameter.filter((item) => item != e.target.value);
        }
        router.query.parameter = parameter;
        setCheckedParameters(parameter);
        router.push(router);
    }

    const handleBranchOffice = (e: any) => {
        let bo = [...checkedBranchOffice];

        if(e.target.checked) {
            bo.push(e.target.value);
        } else {
            bo = bo.filter((item) => item != e.target.value);
        }
        router.query.branchOffice = bo;
        setCheckedBranchOffice(bo);
        router.push(router);
    }

    const handleTag = (e: any) => {
        let bo = [...checkedTag];

        if(e.target.checked) {
            bo.push(e.target.value);
        } else {
            bo = bo.filter((item) => item != e.target.value);
        }
        router.query.tag = bo;
        setCheckedTag(bo);
        router.push(router);
    }

    const handleFilterVariant = (e: any) => {
        let parameter = [...checkedVariants];

        if(e.target.checked) {
            parameter.push(e.target.value);
        } else {
            parameter = parameter.filter((item) => item != e.target.value);
        }
        router.query.variant = parameter;
        setCheckedVariants(parameter);
        router.push(router);
    }

    const handleCloseFilter = () => {
        closeSidebar()
    }

    const isCheckedParameter = (id: string) => {
        for(let i = 0; i<checkedParameters.length; i++){
            if(checkedParameters[i] === id) {
                return true;
            }
        }

        return false;
    }

    const isCheckedBranchOffice = (id: string) => {
        for(let i = 0; i<checkedBranchOffice.length; i++){
            if(checkedBranchOffice[i] === id) {
                return true;
            }
        }

        return false;
    }

    const isCheckedTag = (id: string) => {
        for(let i = 0; i<checkedTag.length; i++){
            if(checkedTag[i] === id) {
                return true;
            }
        }

        return false;
    }

    const isCheckedVariant = (id: string) => {
        for(let i = 0; i<checkedVariants.length; i++){
            if(checkedVariants[i] === id) {
                return true;
            }
        }

        return false;
    }

    const groupVariants: any = {};
    return (
        <>

                    <h2 className="screenReaderElement">Filtr</h2>

                        <h3>{t('Cenové rozpětí')}</h3>
                        <div className="price-range-slider">
                            <Range min={filterPrice.min} value={rangePrice} max={filterPrice.max} step={1} onChange={handleSliderChange} onAfterChange={handleSliderAfterChange} />
                            <div className="price-range-values">
                                <span id="prv1">{settings ? formatPrice(rangePrice[0], 1, settings.currency) : ''}</span>
                                <span id="prv2">{settings ? formatPrice(rangePrice[1], 1, settings.currency) : ''}</span>
                            </div>
                        </div>

            {false && <h3>{t("Příznaky")}</h3>}
                    <div className="form-wide" style={{marginTop: 20}}>
                        <input type="checkbox" name={"tag[]"} onChange={handleTag} checked={isCheckedTag(`e9d81a82-690d-423d-a48d-c436945dd884`)} value={`e9d81a82-690d-423d-a48d-c436945dd884`} id="e9d81a82-690d-423d-a48d-c436945dd884" />
                        <label htmlFor="e9d81a82-690d-423d-a48d-c436945dd884" >
                            {t("Akce")}
                        </label>
                    </div>

                    <div className="form-wide">
                        <input type="checkbox" name={"tag[]"} onChange={handleTag} checked={isCheckedTag(`cef847c4-a390-4fa5-9cf9-44d9150b631e`)} value={`cef847c4-a390-4fa5-9cf9-44d9150b631e`} id="cef847c4-a390-4fa5-9cf9-44d9150b631e" />
                        <label htmlFor="cef847c4-a390-4fa5-9cf9-44d9150b631e" >
                            {t("Doporučujeme")}
                        </label>
                    </div>

                    <div className="form-wide">
                        <input type="checkbox" name={"tag[]"} onChange={handleTag} checked={isCheckedTag(`06e5e39d-ea7d-4968-8065-d1f757cecc9a`)} value={`06e5e39d-ea7d-4968-8065-d1f757cecc9a`} id="06e5e39d-ea7d-4968-8065-d1f757cecc9a" />
                        <label htmlFor="06e5e39d-ea7d-4968-8065-d1f757cecc9a" >
                            {t("Novinka")}
                        </label>
                    </div>

            <div className="form-wide">
                <input type="checkbox" name={"tag[]"} onChange={handleTag} checked={isCheckedTag(`50f67b98-0963-11ee-9243-0242ac110002`)} value={`50f67b98-0963-11ee-9243-0242ac110002`} id="50f67b98-0963-11ee-9243-0242ac110002" />
                <label htmlFor="50f67b98-0963-11ee-9243-0242ac110002" >
                    {t("Bestseller")}
                </label>
            </div>

            <div className="form-wide">
                <input type="checkbox" name={"tag[]"} onChange={handleTag} checked={isCheckedTag(`8d9dfb5f-0963-11ee-9243-0242ac110002`)} value={`8d9dfb5f-0963-11ee-9243-0242ac110002`} id="8d9dfb5f-0963-11ee-9243-0242ac110002" />
                <label htmlFor="8d9dfb5f-0963-11ee-9243-0242ac110002" >
                    {t("Limitovaná kolekce")}
                </label>
            </div>

                        {branchOffices?.length > 0 &&<><h3>{t("Zobrazit pouze skladem na")}</h3>
                            {branchOffices?.map((item: any, index: number) =><div key={item.id} className="form-wide">
                                <input type="checkbox" name={"branchOffice[]"} onChange={handleBranchOffice} checked={isCheckedBranchOffice(`${item.id}`)} value={`${item.id}`} id={item.id} />
                                <label htmlFor={item.id} >
                                    {item.name}
                                </label>
                            </div>)}
                        </>}

                        {paramFilter.map((row, i) => <>{(row.name.toLowerCase() !== 'barva' && row.name.toLowerCase() !== 'color') && <><h3>{row.name}</h3>
                            {row.items?.map((item: any, index: number) =><div key={item.id} className="form-wide">
                                    <input type="checkbox" name={"parameter[]"} onChange={handleFilter} checked={isCheckedParameter(`${row.id}_${item.id}`)} value={`${row.id}_${item.id}`} id={item.id} />
                                    <label htmlFor={item.id} >
                                        {item.value}
                                    </label>
                                </div>)}
                            </>}
                            </>)}



            <div className="colors-select">
                            {variants.map((row, i) => <><h3>{row.name}</h3>
                                <div className={row.items.map((item: any) => { return item.color ? 'colors-select-items' : null }).join("").length > 0 ? 'colors-select-items' : ''}>
                                {row.items?.map((item: any, index: number) => {
                                    const itemGroupExists = groupVariants[item.group];
                                    groupVariants[item.group] = item.group;
                                    return <>
                                       {(item.color && (item.group === null || item.group === '' || !itemGroupExists)) && <>
                                           <input type="checkbox" onChange={handleFilterVariant} value={`${row.id}_${item.group ? item.group : item.id}`} checked={isCheckedVariant(`${row.id}_${item.group ? item.group : item.id}`)} id={item.id}/>
                                           <label htmlFor={item.id}>
                                               <svg xmlns="http://www.w3.org/2000/svg" width="48.002" height="29.005" viewBox="0 0 48.002 29.005">
                                                   <g id="icons8-detective-hat" transform="translate(0.002 -8.996)">
                                                       <path fill={item.color} data-name="Union 2" d="M-11002.971-1184.433a19.694,19.694,0,0,1-5.143-2.045c-1.252-.782-1.888-1.6-1.888-2.426,0-1.862,3.283-3.6,9.011-4.764v-.036c0-.03,0-.055.006-.08,0,0,0,0,0,0a.229.229,0,0,1,0-.036c.188-1.666.486-3.791.942-5.988a10.267,10.267,0,0,0,1.3.568,10.384,10.384,0,0,1-1.3-.568,29.444,29.444,0,0,1,2.644-7.955c1.354-2.481,2.987-3.741,4.847-3.741a7.463,7.463,0,0,1,3.777,1.252,5.318,5.318,0,0,0,2.771.782,5.3,5.3,0,0,0,2.766-.782,7.48,7.48,0,0,1,3.777-1.252c4.178,0,6.38,6.355,7.491,11.688l-.048.025.048-.025c.461,2.2.757,4.33.947,6,.006.044,0,.088,0,.133v-.061a.16.16,0,0,0,0-.036.117.117,0,0,1,0,.03l0,.017a.128.128,0,0,0,0,.033v.033c5.725,1.166,9,2.9,9,4.764,0,.829-.633,1.644-1.885,2.426a19.726,19.726,0,0,1-5.145,2.045A67.551,67.551,0,0,1-10986-1182.5,67.549,67.549,0,0,1-11002.971-1184.433Zm3.062-9.925A43.561,43.561,0,0,0-10986-1192.5a43.482,43.482,0,0,0,13.913-1.865c-.172-1.4-.376-2.744-.614-4.026-1.989.768-6.12,1.89-13.3,1.89-7.2,0-11.314-1.114-13.3-1.882C-10999.536-1197.1-10999.741-1195.751-10999.909-1194.359Zm27.953,1.009.038-.013Zm.07-.023q.469-.157.87-.311Q-10971.418-1193.531-10971.886-1193.373Zm-29.1-.311h0v0Zm14.981-3.813Zm-.2,0h0Zm-.209,0h0Zm.833,0h0Zm.209,0h0Zm-1.26,0h0Z" transform="translate(11009.999 1220.497)" />
                                                   </g>
                                               </svg>

                                              {item.group}
                                           </label>
                                       </>}
                                       {!item.color && <div key={item.id} className="form-wide" style={{width: '33.33%', display: 'inline-block'}}>
                                            <input type="checkbox" name={"parameter[]"} onChange={handleFilterVariant} checked={isCheckedVariant(`${row.id}_${item.group ? item.group : item.id}`)} value={`${row.id}_${item.group ? item.group : item.id}`} id={`s${index}`} />
                                            <label htmlFor={`s${index}`} >
                                                {item.value}
                                            </label>
                                        </div>}
                                    </>}
                                )}
                                </div>
                            </>)}
                        </div>

                        {/*<div className="colors-select">
                            <h3>Barva</h3>
                            <div className="colors-select-items">
                                <input type="checkbox" id="c1"/>
                                <label htmlFor="c1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48.002" height="29.005" viewBox="0 0 48.002 29.005">
                                        <g id="icons8-detective-hat" transform="translate(0.002 -8.996)">
                                            <path fill={"red"} data-name="Union 2" d="M-11002.971-1184.433a19.694,19.694,0,0,1-5.143-2.045c-1.252-.782-1.888-1.6-1.888-2.426,0-1.862,3.283-3.6,9.011-4.764v-.036c0-.03,0-.055.006-.08,0,0,0,0,0,0a.229.229,0,0,1,0-.036c.188-1.666.486-3.791.942-5.988a10.267,10.267,0,0,0,1.3.568,10.384,10.384,0,0,1-1.3-.568,29.444,29.444,0,0,1,2.644-7.955c1.354-2.481,2.987-3.741,4.847-3.741a7.463,7.463,0,0,1,3.777,1.252,5.318,5.318,0,0,0,2.771.782,5.3,5.3,0,0,0,2.766-.782,7.48,7.48,0,0,1,3.777-1.252c4.178,0,6.38,6.355,7.491,11.688l-.048.025.048-.025c.461,2.2.757,4.33.947,6,.006.044,0,.088,0,.133v-.061a.16.16,0,0,0,0-.036.117.117,0,0,1,0,.03l0,.017a.128.128,0,0,0,0,.033v.033c5.725,1.166,9,2.9,9,4.764,0,.829-.633,1.644-1.885,2.426a19.726,19.726,0,0,1-5.145,2.045A67.551,67.551,0,0,1-10986-1182.5,67.549,67.549,0,0,1-11002.971-1184.433Zm3.062-9.925A43.561,43.561,0,0,0-10986-1192.5a43.482,43.482,0,0,0,13.913-1.865c-.172-1.4-.376-2.744-.614-4.026-1.989.768-6.12,1.89-13.3,1.89-7.2,0-11.314-1.114-13.3-1.882C-10999.536-1197.1-10999.741-1195.751-10999.909-1194.359Zm27.953,1.009.038-.013Zm.07-.023q.469-.157.87-.311Q-10971.418-1193.531-10971.886-1193.373Zm-29.1-.311h0v0Zm14.981-3.813Zm-.2,0h0Zm-.209,0h0Zm.833,0h0Zm.209,0h0Zm-1.26,0h0Z" transform="translate(11009.999 1220.497)" />
                                        </g>
                                    </svg>
                                    Beige
                                </label>
                                <input type="checkbox" id="c1"/>
                                <label htmlFor="c1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48.002" height="29.005" viewBox="0 0 48.002 29.005">
                                        <g id="icons8-detective-hat" transform="translate(0.002 -8.996)">
                                            <path fill={"red"} data-name="Union 2" d="M-11002.971-1184.433a19.694,19.694,0,0,1-5.143-2.045c-1.252-.782-1.888-1.6-1.888-2.426,0-1.862,3.283-3.6,9.011-4.764v-.036c0-.03,0-.055.006-.08,0,0,0,0,0,0a.229.229,0,0,1,0-.036c.188-1.666.486-3.791.942-5.988a10.267,10.267,0,0,0,1.3.568,10.384,10.384,0,0,1-1.3-.568,29.444,29.444,0,0,1,2.644-7.955c1.354-2.481,2.987-3.741,4.847-3.741a7.463,7.463,0,0,1,3.777,1.252,5.318,5.318,0,0,0,2.771.782,5.3,5.3,0,0,0,2.766-.782,7.48,7.48,0,0,1,3.777-1.252c4.178,0,6.38,6.355,7.491,11.688l-.048.025.048-.025c.461,2.2.757,4.33.947,6,.006.044,0,.088,0,.133v-.061a.16.16,0,0,0,0-.036.117.117,0,0,1,0,.03l0,.017a.128.128,0,0,0,0,.033v.033c5.725,1.166,9,2.9,9,4.764,0,.829-.633,1.644-1.885,2.426a19.726,19.726,0,0,1-5.145,2.045A67.551,67.551,0,0,1-10986-1182.5,67.549,67.549,0,0,1-11002.971-1184.433Zm3.062-9.925A43.561,43.561,0,0,0-10986-1192.5a43.482,43.482,0,0,0,13.913-1.865c-.172-1.4-.376-2.744-.614-4.026-1.989.768-6.12,1.89-13.3,1.89-7.2,0-11.314-1.114-13.3-1.882C-10999.536-1197.1-10999.741-1195.751-10999.909-1194.359Zm27.953,1.009.038-.013Zm.07-.023q.469-.157.87-.311Q-10971.418-1193.531-10971.886-1193.373Zm-29.1-.311h0v0Zm14.981-3.813Zm-.2,0h0Zm-.209,0h0Zm.833,0h0Zm.209,0h0Zm-1.26,0h0Z" transform="translate(11009.999 1220.497)" />
                                        </g>
                                    </svg>
                                    Beige
                                </label>
                            </div>
                        </div>
                       <h3>Kolekce</h3>
                        <div className="form-wide">
                            <input type="checkbox" id="s12"/>
                            <label htmlFor="s12">
                                Letní kolekce 2020
                            </label>
                        </div>*/}
                        <div className="form-wide">
                            <button type="button" onClick={handleCloseFilter} className="btn btn-background">{t("Zobrazit")}</button>
                        </div>

        </>
    )
}

export default Filter
