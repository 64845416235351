export const formatPrice = (input: number, quantity: number, option: {
    decimalSeparator: string,
    thousandSeparator: string,
    symbolBefore: string,
    symbolAfter: string,
    exchangeRate: number,
    deliveryPrice: number,
    paymentPrice: number,
}, clear?: boolean, isWholesale?: boolean): any => {

    // let price: number = Math.floor(input/option.exchangeRate);  // způsobuje chybu zaokrouhlení pak z 10.89 udělá 10 ale *2 udělá 21 *3 32 atd.. nesmysl
    let price: number = input/option.exchangeRate;

    if(option.deliveryPrice) {
        price += option.deliveryPrice;
    }
    if(option.paymentPrice) {
        price += option.paymentPrice;
    }

    if (clear) {
        if (option.symbolBefore) {
            return option.symbolBefore + " " + formatNumber(price, quantity, option.decimalSeparator, option.thousandSeparator, isWholesale)
        }
        return formatNumber(price, quantity, option.decimalSeparator, option.thousandSeparator, isWholesale) + " " + option.symbolAfter;
    }
    if (option.symbolBefore) {
        return <span dangerouslySetInnerHTML={{__html: option.symbolBefore + " " + formatNumber(price, quantity, option.decimalSeparator, option.thousandSeparator, isWholesale)}} />
    }

    return <span dangerouslySetInnerHTML={{__html: formatNumber(price, quantity, option.decimalSeparator, option.thousandSeparator.replace(' ', '&nbsp;'), isWholesale) + "&nbsp;" + option.symbolAfter}} />
}

export function formatNumber(input: number, quantity: number, decimalSeparator: string, thousandSeparator: string, isWholesale?: boolean)
{
    let price = input;
    if (isWholesale !== true) {
        price = Math.round(input);
    }
    const number = String((((price*100) === 0 ? input : price)*quantity).toFixed(2));
    const x = number.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? decimalSeparator + x[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1) && decimalSeparator.length > 0) {
        x1 = x1.replace(rgx, '$1' + thousandSeparator + '$2');
    }

    if (isWholesale === true || ((price*100) === 0 && input > 0)) {
        return x1 + x2;
    } else {
        return x1;
    }
}
