import {fetcher} from "@framework/fetcher";

const getPaymentMethods = async (country: string, price: number, locale: string, currency: string, type: string) => {
    return await fetcher({
        locale,
        query: {
            country,
            price,
            currency,
            type,
        },
        url: `/payment-methods`,
        method: 'GET',
    });
}
export default getPaymentMethods
