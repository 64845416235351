import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const addFavorite = async (productId: string) => {
    const token = Cookies.get(CUSTOMER_COOKIE)
    return await fetcher({
        url: `/my-account/favorites`,
        body: {
            productId,
        },
        method: 'POST',
        headers: {
            'authorization': 'bearer ' + token
        }
    });
}
export default addFavorite
