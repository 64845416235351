import {ReactElement, useEffect, useState} from 'react';
import Head from 'next/head';
import Link from 'next/link'
import {useRouter} from "next/router";
import {useForm} from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import cn from 'classnames';
// import useTranslation from 'next-translate/useTranslation';

export const siteTitle = 'Insurance App';


export default function Searchbar() {
    const { t } = useTranslation('common');
    const router = useRouter();
    const [visible, setVisible] = useState<boolean>(false);

    const {register, handleSubmit, reset} = useForm();

    useEffect(() => {
        if(router.query.q === undefined) {
           reset({ q: '', qm: '' })
        } else {
           reset({ q: router.query.q, qm: router.query.q })
        }
    }, [router.query.q])

    const onSubmit = (data: any) => {
        router.query.q = data.q ? data.q : data.qm;
        router.pathname = '/search';
        router.push(router)
    }

    const changeVisible = (e: any) => {
        e.preventDefault();
        setVisible(!visible);
    }

    return (
        <><form onSubmit={handleSubmit(onSubmit)} className="header-search">
            <input type="text" {...register('q')} placeholder={t("searchText")} required={true}/>
            <button type="submit">{t("Hledej")}</button>
        </form>
        <a href={"#"} className={"search-icon"} onClick={changeVisible} />
        <form onSubmit={handleSubmit(onSubmit)} className={cn("header-mobile-search", {'visible': visible})}>
                <input type="text" {...register('qm')} placeholder={t("searchText")} required={true}/>
                <button type="submit" className={"btn btn-background"}>{t("Hledej")}</button>
        </form>
        </>
    );
}
