import {fetcher} from "@framework/fetcher";

const getPage = async (id: string, locale: string | undefined) => {
    console.log(`/pages/${id}`)
    return await fetcher({
        locale,
        url: `/pages/${id}`,
    });
}
export default getPage
