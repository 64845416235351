import {fetcher} from "@framework/fetcher";

const convertToBase64 = async (imageUrl: string) => {
    return await fetcher({
        method: 'POST',
        url: `/tester/convert-to-base64`,
        body: {
            imageUrl
        }
    });
}
export default convertToBase64
