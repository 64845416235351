import * as React from 'react';

export const giftBoxColors = (): { color: string; smallImage: string; image: string; }[] => {
    const colors = [
        {
            color: '#8d6e63',
            image: '/images/gift.jpg',
            smallImage: '/images/giftsmall.jpg',
        },
        {
            color: '#f06292',
            image: '/images/box-pink.jpg',
            smallImage: '/images/giftsmall-pink.jpg',
        }
    ]

    return colors;
};