import {fetcher} from "@framework/fetcher";

const forgottenPassword = async (email: string) => {
    return await fetcher({
        method: 'POST',
        url: `/customers/forgotten-password`,
        body: { email }
    });
}
export default forgottenPassword
