import React, {useEffect, useRef, useState} from "react";
import {useUI} from "@components/ui";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import cn from 'classnames';
// import * as faceapi from "face-api.js";
import style from './hugo.module.css';
import HugoStep1 from "@components/product/hugo/hugoStep1";
import HugoStep2 from "@components/product/hugo/hugoStep2";
import {useRouter} from "next/router";


interface iCurrentImage {
    image: string | null,
    position: {
        top: number,
        left: number,
    },
    scale: number,
}

export default function Hugo({...props}) {

    const router = useRouter();
    const {displayModal, closeModal, hugoUrl, hugoProductId} = useUI();
    const [currentStep, setStep] = useState<number>(1);
    const [selectPhoto, setSelectPhoto] = useState<boolean>(false);
    const [openCamera, setOpenCamera] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<iCurrentImage>({
        image: null,
        position: { top: 0, left: 0},
        scale: 0
    })

    useEffect(() => {
        setStep(1)
    }, [displayModal])

    useEffect(() => {
        if (currentStep === 2) {
            closeModal()
        }
    }, [router.pathname])

    const handleNextStep = () => {
        const step = currentStep+1;
        setStep(step == 2 ? 2 : step);
    }

    const handlePrevStep = () => {
        const step = currentStep-1;
        setStep(step == 1 ? 1 : step);
    }

    const handleFileDialog = () => {
        setStep(1);
        setSelectPhoto(true);
        setTimeout(() => {
            setSelectPhoto(false);
        }, 2000)
    }

    const handleOpenCamera = () => {
        setStep(1);
        setOpenCamera(true);
        setTimeout(() => {
            setOpenCamera(false);
        }, 2000)
    }

    return <>
        {currentStep === 1 &&
            <HugoStep1 nextStep={handleNextStep} openFileDialog={handleFileDialog} selectPhoto={selectPhoto} openCamera={openCamera} changeImage={setCurrentImage}  />
        }

        {currentStep === 2 &&
            <HugoStep2 prevStep={handlePrevStep} productId={hugoProductId} hatImage={hugoUrl} image={currentImage} onOpenCamera={handleOpenCamera} onSelectPhoto={handleFileDialog} />
        }
    </>
}
