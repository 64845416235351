import {fetcher} from "@framework/fetcher";

const saveEmailNewsletter = async (email: string) => {
    return await fetcher({
        method: 'POST',
        url: `/connection/mailchimp`,
        body: {
            email
        }
    });
}
export default saveEmailNewsletter
