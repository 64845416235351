import {fetcher} from "@framework/fetcher";

const getMenu = async (locale: string, position: string) => {
    return await fetcher({
        locale,
        query: {
            position
        },
        url: `/menu`,
    });
}
export default getMenu
