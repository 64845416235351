import {FC, ReactElement, useEffect, useState} from 'react';
import cn from 'classnames'
import {useUI} from "@components/ui";
import {giftBoxColors} from "@lib/giftBoxColors";
import useTranslation from "next-translate/useTranslation";

const BalikovnaModal = () => {

    const {t} = useTranslation("common")
    const {setGiftColor, displayModal, closeModal, setDeliveryPoint} = useUI()
    const [modalSize, setModalSize] = useState<{ width: number, height: number }>({ width: 0, height: 0})

    useEffect(() => {
        setModalSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })

        function iframeListener(event: any) {
            if (event.data.message === 'pickerResult') {
                console.log(event.data);
                setDeliveryPoint({
                    id: event.data.point.id,
                    name: event.data.point.name,
                    address: event.data.point.address,
                })
                closeModal();
            }
        }

        window.addEventListener('message', iframeListener);
    }, []);

    return (<>
            <div className={"content-inner"} style={{width: `100%`, height: `${modalSize.height}px`}}>
                <iframe style={{width: `100%`, height: `${modalSize.height}px`}} title="Výběr místa pro vyzvednutí zásilky" src="https://b2c.cpost.cz/locations/?type=BALIKOVNY" allow="geolocation" />
            </div>
    </>);
}
export default BalikovnaModal
