import React, { FC, useEffect, useState, useCallback } from 'react'
import {useRouter} from "next/router";
import { Range } from 'rc-slider';
import storevio from "@lib/api";
import {useUI} from "@components/ui";
import {formatPrice} from "@lib/formatPrice";
import Image from "@components/common/Image";
import cn from 'classnames';
import FlashMessage from "@components/common/FlashMessages";
import useTranslation from "next-translate/useTranslation";


const ChangeVariant = () => {
    const { t } = useTranslation('common');
    const {productVariants, closeSidebar, setSelectedProductVariant} = useUI()
    const [pvi, setPvi] = useState<any[]>();

    useEffect(() => {
        setPvi(productVariants.variant?.items);
    }, [productVariants.variant?.items])


    const handleOnChange = (e: any) => {
        setSelectedProductVariant({
            "variantId": productVariants.variant?.id,
            "valueId": e.target.value
        });
        closeSidebar()
    }

    const handleOnSelect = (id: any) => {
        setSelectedProductVariant({
            "variantId": productVariants.variant?.id,
            "valueId": id
        });
        closeSidebar()
    }

    const handleOnFilter = (e: any) => {
        const pom:any = [];
        productVariants.variant?.items.map((item: any) => {
            if (item.value.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) > -1 || item.internalColorCode.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) > -1) {
                pom.push(item);
            }
        })
        setPvi(pom);
    }

    return (
        <>
            <div className={"change-variant"}>
                <div className="more-products"><h3>{productVariants.variant?.name}</h3>
                    <input type={"text"} placeholder={t("Zadejte barvu nebo kód")} onKeyUp={handleOnFilter} style={{marginBottom: -1}} />
                    {pvi?.map((item: any, index: number) => <a key={index} href={"#"} className={cn({['checked'] : productVariants.currentVariant === item.id})} onClick={(e) => {
                        e.preventDefault(); handleOnSelect(item.id);
                    }}>
                        <div className="more-product-image">
                            <Image src={item.image} width={160} height={106} fit={"contain"}/>
                        </div>
                        <div className="more-product-text"><h4>{item.value}{item.internalColorCode && <><br />{item.internalColorCode}</>}</h4></div>
                    </a>)}

                    {pvi?.length === 0 && <div className="orange-alert" style={{marginTop: 10}}>{t("Vámi zvolená barva nebyla nalezena")}</div>}
                </div>
            </div>
            {/*<div className="colors-select">
                <><h3>{productVariants.variant?.name}</h3>
                    <div className={productVariants.variant?.items.map((item: any) => { return item.color ? 'colors-select-items' : null }).join("").length > 0 ? 'colors-select-items' : ''}>
                        {productVariants.variant?.items?.map((item: any, index: number) =>
                            <>
                                {item.color && <>
                                    <input type="radio" onChange={handleOnChange} checked={productVariants.currentVariant === item.id} value={`${item.id}`} id={item.id}/>
                                    <label htmlFor={item.id}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48.002" height="29.005" viewBox="0 0 48.002 29.005">
                                            <g id="icons8-detective-hat" transform="translate(0.002 -8.996)">
                                                <path fill={item.color} data-name="Union 2" d="M-11002.971-1184.433a19.694,19.694,0,0,1-5.143-2.045c-1.252-.782-1.888-1.6-1.888-2.426,0-1.862,3.283-3.6,9.011-4.764v-.036c0-.03,0-.055.006-.08,0,0,0,0,0,0a.229.229,0,0,1,0-.036c.188-1.666.486-3.791.942-5.988a10.267,10.267,0,0,0,1.3.568,10.384,10.384,0,0,1-1.3-.568,29.444,29.444,0,0,1,2.644-7.955c1.354-2.481,2.987-3.741,4.847-3.741a7.463,7.463,0,0,1,3.777,1.252,5.318,5.318,0,0,0,2.771.782,5.3,5.3,0,0,0,2.766-.782,7.48,7.48,0,0,1,3.777-1.252c4.178,0,6.38,6.355,7.491,11.688l-.048.025.048-.025c.461,2.2.757,4.33.947,6,.006.044,0,.088,0,.133v-.061a.16.16,0,0,0,0-.036.117.117,0,0,1,0,.03l0,.017a.128.128,0,0,0,0,.033v.033c5.725,1.166,9,2.9,9,4.764,0,.829-.633,1.644-1.885,2.426a19.726,19.726,0,0,1-5.145,2.045A67.551,67.551,0,0,1-10986-1182.5,67.549,67.549,0,0,1-11002.971-1184.433Zm3.062-9.925A43.561,43.561,0,0,0-10986-1192.5a43.482,43.482,0,0,0,13.913-1.865c-.172-1.4-.376-2.744-.614-4.026-1.989.768-6.12,1.89-13.3,1.89-7.2,0-11.314-1.114-13.3-1.882C-10999.536-1197.1-10999.741-1195.751-10999.909-1194.359Zm27.953,1.009.038-.013Zm.07-.023q.469-.157.87-.311Q-10971.418-1193.531-10971.886-1193.373Zm-29.1-.311h0v0Zm14.981-3.813Zm-.2,0h0Zm-.209,0h0Zm.833,0h0Zm.209,0h0Zm-1.26,0h0Z" transform="translate(11009.999 1220.497)" />
                                            </g>
                                        </svg>
                                        {productVariants.availableVariants.indexOf(item.id) == -1 && <del>{item.value}</del>}
                                        {productVariants.availableVariants.indexOf(item.id) != -1 && <>{item.value}</>}
                                    </label>
                                </>}
                                {!item.color && <div key={item.id}  className="form-wide" style={{width: '33.33%', display: 'inline-block'}}>
                                    <input type="radio" checked={productVariants.currentVariant === item.id} onChange={handleOnChange} value={`${item.id}`} id={`s${index}`} />
                                    <label htmlFor={`s${index}`}>
                                        {productVariants.availableVariants.indexOf(item.id) == -1 && <del>{item.value}</del>}
                                        {productVariants.availableVariants.indexOf(item.id) != -1 && <>{item.value}</>}
                                    </label>
                                </div>}
                            </>
                        )}
                    </div>
                </>
            </div>*/}
        </>
    )
}

export default ChangeVariant
