import React, {FC, useState} from 'react';
import Link from 'next/link'
import {useForm} from "react-hook-form";
import * as yup from "yup";
import useTranslation from "next-translate/useTranslation";
import FlashMessage, {FlashMessageProps} from "@components/common/FlashMessages/FlashMessage";
import {yupResolver} from "@hookform/resolvers/yup";
import cn from "classnames";
import storevio from "@lib/api";


interface Link {
    id: string
    slug: string
    name: string
}

interface NavbarProps {
    links?: Link[]
}

const Newsletter: FC<NavbarProps> = ({ links }) => {
    const { t } = useTranslation('common');

    const schema = yup.object().shape({
        email: yup.string().email(t("Zadejte platnou e-mailovou adresu")).required(t("Emailová adresa je povinná")),
        agreeWithConditions: yup.boolean().oneOf([true], t("conditionAgreeRequired")),
    });

    const { register, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(schema),
    });

    const [message, setMessage] = useState<FlashMessageProps>({message: "", type: 'info'})



    const onSubmit = async (data: any) => {
        const res = await storevio.saveEmailNewsletter(data.email)
        if(res?.status === 'ok') {
            setMessage({message: t('Přihlášení k newslettru proběhlo úspěšně'), type: 'success' });
        } else if(res?.status === 'unspecified-error') {
            setMessage({message: t("Přihlášení k newslettru se nezdařilo"), type: 'danger' });
        } else {
            setMessage({message: t("Emailová adresa je již registrována k odběru"), type: 'danger' });
        }

    }

    return (
        <div className="modal-content-inner">
            <h2>{t("Přihlášení k newslettru")}</h2>

            <FlashMessage message={message.message} type={message.type} />

            <form onSubmit={handleSubmit(onSubmit)} style={{width: '70%', textAlign: 'left', margin: '0 auto'}}>
                <div className="form-wide">
                    <label>
                        {t("Váš e-mail")}
                    </label>
                    <input type="email"
                           className={cn({"error-input": errors.email})}
                           {...register("email")}
                    />
                    {errors.email && <span className="error-item">{errors.email?.message}</span>}
                </div>
                <div className="form-wide">
                    <input type="checkbox" id="ch1"
                           className={"error-input"}
                           {...register("agreeWithConditions", { required: true })}
                    />
                    <label htmlFor="ch1">
                        {t("newsletterConditions")}
                    </label>
                    {errors.agreeWithConditions &&
                        <span className="error-item">{errors.agreeWithConditions?.message}</span>}
                </div>
                <div className="form-wide form-wide-last">
                    <button disabled={isSubmitting} type="submit" className="btn btn-background">{t("Odebírat")}</button>
                </div>
            </form>
        </div>
    );
}
export default Newsletter
