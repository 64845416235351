import dynamic from "next/dynamic";
import Link from 'next/link'
import 'owl.carousel/dist/assets/owl.carousel.css';
import {getProductPath} from "@lib/links";
import MarkFavorite from "./markFavorite";
import {formatPrice} from "@lib/formatPrice";
import Image from '@components/common/Image';
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import {hasVariantDifferentPrice, selectFirstVariant} from "@lib/selectFirstVariant";
import cn from 'classnames';
import useTranslation from "next-translate/useTranslation";
import Price from "@components/common/Price";
import useCustomer from "@framework/customer/use-customer";

export default function Product({...props}) {

    const { t } = useTranslation('common');
    const router = useRouter();
    const priceOption = props.options.currency;
    const [colorsVariants, setColorVariants] = useState<any>([]);
    const [variant, setVariants] = useState<any>(selectFirstVariant(props.variants, router.query));
    //const [variantIndex, setVariantIndex] = useState<number>(0);

    useEffect(() => {
       if(props.hasVariants) {
           const pom: any[] = [];
           props.variants?.map((variant: any) => {
               //
               let queryHasSize = false;
               if (router.query.variant !== undefined) {
                   let _v = [];
                   if (typeof router.query.variant === 'string') {
                       _v.push(router.query.variant.split('_')[0]);
                   } else {
                       _v = router.query.variant?.map((_) => _.split('_')[0]);
                   }

                   queryHasSize = _v.indexOf('f75157e7-d28e-4156-bbf7-f7c5d4ccc297') > -1;
               }


               // f75157e7-d28e-4156-bbf7-f7c5d4ccc297_f996d732-6717-4c74-9a1f-175af10fdc00

               console.log("ahs ", queryHasSize);
               if (queryHasSize) {
                   const color = variant.params.filter((param: any) => param.param.id === 'd239e9aa-601c-4cc0-9ef7-af27c19b4c26')[0];
                   const size = variant.params.filter((param: any) => param.param.id === 'f75157e7-d28e-4156-bbf7-f7c5d4ccc297')[0];
                   let _v: any = [];
                   if (typeof router.query.variant === 'string') {
                       _v.push(router.query.variant.split('_')[1]);
                   } else {
                       _v = router.query.variant?.map((_: any) => _.split('_')[1]);
                   }

                  //console.log(size.param.id);
                   if (_v.indexOf(size.value.id) > -1) {
                       variant["color"] = color.value.value;
                       pom[color.value.id] = variant;
                   }

               } else {
                   variant.params?.map((param: any) => {
                       if (param.value.color && pom[param.value.id] === undefined) {
                           variant["color"] = param.value.value;
                           pom[param.value.id] = variant;
                       }
                   });
               }
           });

           const colorsVariantsValues = Object.values(pom);
           colorsVariantsValues.sort((a: { color: string; }, b: { color: string; }) => {
                   let fa = a.color.toLowerCase(),
                       fb = b.color.toLowerCase();

                   if (fa < fb) return -1;
                   if (fa > fb) return 1;
                   return 0;
               })

           /*let queryVariants: any = router.query?.variant;
           let foundIndex = 0;
           if(!Array.isArray(queryVariants)) {
               queryVariants = [router.query?.variant];
           }*/

          // queryVariants = queryVariants.filter((val: any) => val != '');


           /*colorsVariantsValues.map((variant: any, index: number) => {
               variant.params.map((param: any) => {
                   queryVariants.map((qv: any) => {
                       const id = qv?.split("_")[0];
                       const valueId = qv?.split("_");

                       if(id === param.param.id && valueId[1] === param.value.id && param.value.color) {
                           foundIndex = index
                       }
                   });
               })
           })*/

          // setVariantIndex(foundIndex);
          setColorVariants(colorsVariantsValues);
          setVariants(selectFirstVariant(props.variants, router.query));
       }
    }, [router.query.variant, props.product]);


    // const { t } = useTranslation('common');
    const OwlCarousel = dynamic(() => import("react-owl-carousel"), {ssr: false});

    if (props?.openHugo !== undefined) {
        return <div className="product-card">
            <div className="product-card-image">
                <a href={"#"}  onClick={(e) => { e.preventDefault(); props.openHugo(props.id, props.hasVariants ? variant.testerPhoto : props.testerPhoto)}}>
                    {props.image && <Image src={props.image} width={428} height={214} />}
                    {(props.hasVariants) && <Image src={variant.mainPhoto} width={428} height={214} />}
                </a>
            </div>
            <div className="product-card-info">
                <h3>
                    <a href={"#"} onClick={(e) => { e.preventDefault(); props.openHugo(props.id, props.hasVariants ? variant.testerPhoto : props.testerPhoto)}}>
                       {props.name}
                    </a>
                </h3>
                {props.hasVariants && <div className="product-card-price">
                    <Price {...variant?.price} actionPrice={variant?.actionPrice} priceOption={priceOption} />
                </div>}
                {!props.hasVariants && <div className="product-card-price">
                    <Price {...props?.price}  actionPrice={props?.product.actionPrice} priceOption={priceOption} />
                </div>}
                <a href={"#"} style={{textAlign: 'left', width: '160px'}} onClick={(e) => { e.preventDefault(); props.openHugo(props.id, props.hasVariants ? variant.testerPhoto : props.testerPhoto)}} className="btn-buy btn-hugo">
                    {t("Vyzkoušet")}
                </a>
                {colorsVariants.length > 0 && <div className="product-card-info-slider">
                    <OwlCarousel className={cn('variants-carousel', 'owl-carousel', {['disable']: colorsVariants.length <= 4} )} items={4} >
                        {colorsVariants?.map((variant: any, index: number) => <div key={index} className="slide">
                            <a href={"#"} onClick={(e) => { e.preventDefault(); props.openHugo(props.id, variant.testerPhoto)}}>
                                    {variant.mainPhoto && <Image src={variant.mainPhoto} width={84} height={56} fit={"contain"} />}
                            </a>
                        </div>)}
                    </OwlCarousel>
                </div>}
                {(colorsVariants.length === 0 && props?.product?.images?.length > 0 )  && <div className="product-card-info-slider">
                    <OwlCarousel className={cn('variants-carousel', 'owl-carousel', {['disable']: props?.product?.images?.length <= 4} )} items={4} >
                        {props?.product?.images?.map((photo: any, index: number) => <div key={index} className="slide">
                            <a href={"#"} onClick={(e) => { e.preventDefault(); props.openHugo(props.id, props.testerPhoto)}}>
                                {photo.url && <Image src={photo.url} width={84} height={56} fit={"contain"} />}
                            </a>
                        </div>)}
                    </OwlCarousel>
                </div>}
            </div>

            <MarkFavorite id={props.id} key={props.id} />

            {props?.tags?.length > 0 && <div className="product-tags">

                {props?.tags?.map((tag: any, index: number) => <>{((tag.tag.id === 'e9d81a82-690d-423d-a48d-c436945dd884' && variant?.actionPrice?.amount) || tag.tag.id !== 'e9d81a82-690d-423d-a48d-c436945dd884') && <span key={index}> <span>{tag.tag.translations[0].name}
                    {tag.tag.id === 'e9d81a82-690d-423d-a48d-c436945dd884' ? ' (-' + (100-Math.round(
                        (props.hasVariants ? ((variant.actionPrice.amount ?? 0)/variant.price.amount) : ((props.actionPrice.amount ?? 0)/props.price.amount))*100
                    )) + '%)' : ''}
                </span></span>}</>)}
            </div>}
        </div>
    }

    return (
        <div className="product-card">
            <div className="product-card-image">
                <Link href={getProductPath(props.slug + "?variantId=" + variant.id)}>
                    <a>
                        {props.image && <Image src={props.image} width={428} height={214} />}
                        {(props.hasVariants) && <Image src={variant.mainPhoto} width={428} height={214} />}
                    </a>
                </Link>
            </div>
            <div className="product-card-info">
                <h3>
                    <Link href={getProductPath(props.slug + "?variantId=" + variant.id)}>
                        <a>{props.name}</a>
                    </Link>
                </h3>
                {props.hasVariants && <div className="product-card-price">
                    {hasVariantDifferentPrice(props.variants) && <>{t('od')}</>} <Price {...variant?.price} actionPrice={variant?.actionPrice} priceOption={priceOption} />
                    {/*formatPrice(variant?.actionPrice ? variant?.actionPrice : variant?.price, priceOption)*/}
                    {/*variant?.actionPrice && <del style={{marginLeft: '5px', fontSize: '10px'}}>
                        {formatPrice( variant?.price, priceOption)}
                    </del>*/}
                </div>}
                {!props.hasVariants && <div className="product-card-price">
                    <Price {...props.price} actionPrice={props.product.actionPrice} priceOption={priceOption} />
                </div>}
               <Link href={getProductPath(props.slug + "?variantId=" + variant.id)}>
                   {(props.product.type === 'PRODUCT' || (props.product.type !== 'PRODUCT' && router.locale === 'cs')) ? <a className="btn-buy">
                        <span>{t("Koupit")}</span>
                    </a> : <p style={{padding: 20}}></p>}
                </Link>
                {colorsVariants.length > 0 && <div className="product-card-info-slider">
                    <OwlCarousel className={cn('variants-carousel', 'owl-carousel', {['disable']: colorsVariants.length <= 4} )} items={4} >
                        {colorsVariants?.map((variant: any, index: number) => <div key={index} className="slide">
                            <Link href={getProductPath(props.slug + "?variantId=" + variant.id)}>
                                <a>
                                    {variant.mainPhoto && <Image src={variant.mainPhoto} width={84} height={56} fit={"contain"} />}
                                </a>
                            </Link>
                        </div>)}
                    </OwlCarousel>
                </div>}
                {(colorsVariants.length === 0 && props?.product?.images?.length > 0 )  && <div className="product-card-info-slider">
                    <OwlCarousel className={cn('variants-carousel', 'owl-carousel', {['disable']: props?.product?.images?.length <= 4} )} items={4} >
                        {props?.product?.images?.map((photo: any, index: number) => <div key={index} className="slide">
                            <Link href={getProductPath(props.slug)}>
                                <a>
                                    {photo.url && <Image src={photo.url} width={84} height={56} fit={"contain"} />}
                                </a>
                            </Link>
                        </div>)}
                    </OwlCarousel>
                </div>}
            </div>

            <MarkFavorite id={props.id} key={props.id} />

            {props?.tags?.length > 0 && <div className="product-tags">
                {props?.tags?.map((tag: any, index: number) => <>{((tag.tag.id === 'e9d81a82-690d-423d-a48d-c436945dd884' && variant?.actionPrice?.amount) || tag.tag.id !== 'e9d81a82-690d-423d-a48d-c436945dd884') && <span key={index}> <span>{tag.tag.translations[0].name}
                    {tag.tag.id === 'e9d81a82-690d-423d-a48d-c436945dd884' ? ' (-' + (100-Math.round(
                        (props.hasVariants ? ((variant.actionPrice.amount ?? 0)/variant.price.amount) : ((props.product.actionPrice.amount ?? 0)/props.product.price.amount))*100
                    )) + '%)' : ''}
                </span></span>}</>)}
            </div>}
        </div>
    );
}
