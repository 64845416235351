import {FC, ReactElement} from 'react';
import cn from 'classnames'
import styles from "./flashMessage.module.css"

export interface FlashMessageProps {
    message: string,
    type: "info" | "danger" | "success"
}

const FlashMessage: FC<FlashMessageProps> = ({message, type}) => {
    const _type = type.charAt(0).toUpperCase() + type.slice(1);

    if(!message) return <></>
    return (
        <p className={cn([styles.flashMessage, styles[`flashMessage${_type}`]])}>{message}</p>
    );
}
export default FlashMessage
