import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {COOKIE_NAME} from "@framework/const";

const getMe = async (body: any, token: string) => {
    return await fetcher({
        url: `/customers/me`,
        method: 'PUT',
        body,
        headers: {
            'authorization': 'bearer ' + token
        }
    });
}
export default getMe
