import cn from 'classnames'
import React, {
    forwardRef,
    ButtonHTMLAttributes,
    JSXElementConstructor,
    useRef, useState, useEffect,
} from 'react'
import mergeRefs from 'react-merge-refs'
import { LoadingDots } from '@components/ui'
import Loader from "@components/common/Loader";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    href?: string
    className?: string
    active?: boolean
    loaderColor?:string
    type?: 'submit' | 'reset' | 'button'
    Component?: string | JSXElementConstructor<any>
    width?: string | number
    loading?: boolean
    disabled?: boolean
}

const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
    const {
        className,
        children,
        active,
        width,
        loaderColor,
        loading = false,
        disabled = false,
        style = {},
        Component = 'button',
        ...rest
    } = props
    const ref = useRef<HTMLButtonElement>(null)
    const [getButtonWidth, setButtonWidth] = useState(0);

    useEffect(() => {
        if (ref?.current) {
            setButtonWidth(ref?.current.offsetWidth)
        }
    }, [])

    const rootClassName = cn(
        {
            ["loading"]: loading,
            ["disabled"]: disabled,
        },
        className
    )

    return (
        <Component
            aria-pressed={active}
            ref={mergeRefs([ref, buttonRef])}
            className={rootClassName}
            disabled={disabled}
            style={{
                width: (loading ? getButtonWidth + "px" : null),
                ...style,
            }}
            {...rest}
        >
            {!loading && children}
            {loading && (
                <div style={{textAlign: 'center', width: '100%'}}><Loader color={props?.loaderColor} /></div>
            )}
        </Component>
    )
})

Button.displayName = 'Button';
export default Button
