import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const createVoucherOrder = async (body: {
    paymentType: string;
    giftFor: string;
    reaching: string;
    firstname: string;
    lastname: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
    personType: string;
    companyName?: string;
    identificationNumber?: string;
    vatNumber?: string;
    street: string;
    houseNumber: string;
    city: string;
    zipCode: string;
    country: string;
    productId: string;
}, locale: string, currency: string) => {
    const customerToken = Cookies.get(CUSTOMER_COOKIE); //auth ? auth : Cookies.get(CUSTOMER_COOKIE);

    return await fetcher({
        body,
        url: `/gift-vouchers?lang=${locale}&currency=${currency}`,
        headers: {
            'Authorization': 'Bearer ' + customerToken,
        },
        method: 'POST',
    });
}
export default createVoucherOrder
