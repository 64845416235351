import {useForm} from "react-hook-form";
import * as yup from "yup";
import {useRouter} from "next/router";
import {useCart} from "@framework/cart";
import {yupResolver} from "@hookform/resolvers/yup";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import intlTelInput from "intl-tel-input";
import 'intl-tel-input/build/css/intlTelInput.css';
import useCustomer from "@framework/customer/use-customer";
import {useUI} from "@components/ui";
import createReservation from "@framework/api/endpoints/products/createReservation";
import getStocks from "@framework/api/endpoints/getStocks";
import Image from "@components/common/Image/Image";
import Price from "@components/common/Price";

const ReservationProduct = () => {
    const { t } = useTranslation('common');
    const { data: customer, isLoading: isLoadingCustomer, error: customerError } = useCustomer();
    const { productId, variantId, productInfo, displayModal } = useUI()
    const [message, setMessage] = useState<{type: string; content: string} | null>(null)
    const [getStockLists, setStockLists] = useState<any>([]);

    const schema = yup.object().shape({
        stockId: yup.string().nullable().required(t("Vyberte kde si chcete produkt rezervovat")),
        reaching: yup.string().nullable().required(t("Vyberte jak vás máme oslovovat")),
        firstname: yup.string().nullable().required(t("Jméno je povinné")),
        lastname: yup.string().nullable().required(t("Příjmení je povinné")),
        email: yup.string().nullable().email(t("Zadejte platnou e-mailovou adresu")).required(t("Emailová adresa je povinná")),
        //phonePrefix: yup.string().required("Vyberte předvolbu"),
        phoneNumber: yup.string().nullable().required(t("Telefonní číslo je povinné")),
    });
    const router = useRouter()
    const {register, reset, handleSubmit, watch, setValue, formState: { isSubmitting, errors }} = useForm({ resolver: yupResolver(schema) });

    const onSubmit = async (body: any) => {
        body.productId = productInfo.product.id;
        body.variantId = productInfo.variantId;
        try {
            await createReservation(body, router.locale!);
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('email', '');
            setValue('phoneNumber', '');
            setValue('stockId', '');
            setMessage({
                type: 'green',
                content: t('Vaše rezervace byla úspěšně odeslána'),
            })
        } catch (e) {
            setMessage({
                type: 'orange',
                content: 'Jejda, něco selhalo',
            })
        }
    }



    useEffect(() => {

        setMessage(null);
        setStockLists(productInfo.stocks)

        if (customer) {
            reset({
                reaching: customer.reaching,
                firstname: customer.firstname,
                lastname: customer.lastname,
                email: customer.email,
                phonePrefix: customer.phonePrefix,
                phoneNumber: customer.phoneNumber,
            });
        }

        const input = document.querySelector("#phone");
        if (input) {
            intlTelInput(input, {
                initialCountry: "cz",
                preferredCountries: ["cz", "sk"],
                separateDialCode: true,
            });
            const iti = window.intlTelInputGlobals.getInstance(input);
            if (customer && customer?.phonePrefix !== null) {
                iti.setNumber(customer?.phonePrefix);
            } else {
                setValue("phonePrefix", `+${iti.getSelectedCountryData().dialCode}`);
            }
            input.addEventListener("countrychange", function() {
                setValue("phonePrefix", `+${iti.getSelectedCountryData().dialCode}`);
            });
        }
    }, [customer, isLoadingCustomer, displayModal, productInfo])

    return (<>
        <div className={"content-inner"}>
        <h2>{t('Rezervace produktu')}</h2>
        <form onSubmit={handleSubmit(onSubmit)} className={"buy-form"}>
            {message && <div className={`${message.type}-alert`}>{message.content}</div>}

            <section className="cart-wrap" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15}}>
                <div className="cart-item-lines" style={{paddingBottom: 0}}>
                    <div className="cart-item-line">
                        <div className="cart-item-image">
                            <Image src={productInfo.mainPhoto}  width={555} height={543} />
                           </div>
                        <div className="cart-item-text"><h4>{productInfo.product.name}</h4><strong>
                            {productInfo.product.hasVariants && <>
                                <Price {...productInfo.variant?.price} actionPrice={productInfo.variant?.actionPrice} priceOption={productInfo.settings.currency} showLabel={false} />
                            </>}
                            {!productInfo.product.hasVariants && <>
                                <Price {...productInfo.product.price} actionPrice={productInfo.product.actionPrice} priceOption={productInfo.settings.currency} showLabel={false} />
                            </>}
                            <br /><small>1 {t("ks")}</small></strong>
                            <div className="cart-item-code">{t('Kód produktu')}: {productInfo.product.code ?? productInfo.variant.code}
                                {productInfo.variant.params?.map((param: any) => <><br />{param.param.name}: {param.value.value}</> )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="form-wide" style={{marginTop: 20}}>
                <label>
                    {t("Pobočka")}
                </label>
                <select {...register("stockId")} className={cn({"error-input": errors.stockId})}>
                    <option value={""}>{t("selectPromt")}</option>
                    {getStockLists.map((stock: any,index: number) => <>
                        {stock.isDeliveryPoint &&
                            <option disabled={!(productInfo.inTheStocks[stock.id] !== undefined && parseFloat(productInfo.inTheStocks[stock.id]) > 0)} key={`stock_${index}`} value={stock.id}>
                                {stock.name} {!(productInfo.inTheStocks[stock.id] !== undefined && parseFloat(productInfo.inTheStocks[stock.id]) > 0) && " - " + t('pro rezervaci není produkt dostupný')}
                            </option>
                        }</>
                    )}
                </select>
                {errors.stockId && <span className="error-item">{errors.stockId?.message}</span>}
            </div>
            <div className="form-wide">
                <label>
                    {t("Oslovení")}
                </label>
                <select {...register("reaching")} className={cn({"error-input": errors.firstname})}>
                    <option value={""}>{t("selectPromt")}</option>
                    <option value={"ms"}>{t("Paní")}</option>
                    <option value={"mr"}>{t("Pan")}</option>
                </select>
                {errors.reaching && <span className="error-item">{errors.reaching?.message}</span>}
            </div>
            <div className="form-wide">
                <label>
                    {t("Vaše jméno")}
                </label>
                <input type="text" {...register("firstname")} className={cn({"error-input": errors.firstname})} />
                {errors.firstname && <span className="error-item">{errors.firstname?.message}</span>}
            </div>
            <div className="form-wide">
                <label>
                    {t("Vaše příjmení")}
                </label>
                <input type="text" {...register("lastname")} className={cn({"error-input": errors.lastname})} />
                {errors.lastname && <span className="error-item">{errors.lastname?.message}</span>}
            </div>

            <div className="form-wide">
                <label>
                    {t("Váš e-mail")}
                </label>
                <input type="email" {...register("email")} className={cn({"error-input": errors.email})}/>
                {errors.email && <span className="error-item">{errors.email?.message}</span>}
            </div>
            <div>
                <div className="form-third">
                    <label>
                        {t("Váš telefon")}
                    </label>
                    <input type="text" id={"phone"} {...register("phoneNumber")} className={cn({"error-input": errors.phoneNumber})} />
                    {errors.phoneNumber && <span className="error-item">{errors.phoneNumber?.message}</span>}
                </div>
            </div>

            <div className="form-wide form-wide-last">
                <button type="submit" className="btn btn-background">{t('Rezervovat na prodejně')}</button>
            </div>

        </form>
        </div>
        </>);
}
export default ReservationProduct
