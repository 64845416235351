import React, {useEffect, useRef, useState} from "react";
import {Button, useUI} from "@components/ui";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import cn from 'classnames';
// import * as faceapi from "face-api.js";
import style from './hugo.module.css';
import generatePreview from "@framework/api/endpoints/hugo/generatePreview";
import useCustomer from "@commerce/customer/use-customer";
import convertToBase64 from "@framework/api/endpoints/convertToBase64";
import {scale} from "style-value-types";
import useTranslation from "next-translate/useTranslation";

export default function HugoStep1({...props}) {

    const { t } = useTranslation('common');

    let videoInterval: any = null
    const { displayModal } = useUI()
    const { data: customer } = useCustomer()

    // refs
    const imgRef = useRef() as React.MutableRefObject<HTMLImageElement>
    const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>
    const canvasRef = useRef() as React.MutableRefObject<HTMLCanvasElement>
    const refPhotoSelector = useRef() as React.MutableRefObject<HTMLDivElement>

    // states
    const [getImagePosition, setImagePosition] = useState<{left: number, top: number}>({ left: 0, top: 0 });
    const [getScale, setScale] = useState<number>(1);
    const [getMinScale, setMinScale] = useState<number>(1);
    const [isSelectedPhoto, setSelectedPhoto] = useState<boolean>(false);
    const [getDragging, setDragging] = useState<boolean>(false);
    const [getImageRelPos, setImageRelPos] = useState<{x: number, y: number}>({ x: 0, y: 0 });
    const [isOpenCamera, setOpenCamera] = useState<boolean>(false);
    const [isLoadingCamera, setIsLoadingCamera] = useState<boolean>(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);


    // handlers
    const handleMouseDown = (event: any) => {
        console.log(event);
        if (event !== undefined ) {
            const eventType = event.type.toLowerCase();

            if (eventType === 'touchstart') {
                setImageRelPos({
                    x: event.changedTouches[0].pageX - getImagePosition.left,
                    y: event.changedTouches[0].pageY - getImagePosition.top
                })
            } else if (eventType === 'mousedown') {
                setImageRelPos({
                    x: event.pageX - getImagePosition.left,
                    y: event.pageY - getImagePosition.top
                })
                event.preventDefault()
            }

            setDragging(true)
            event.stopPropagation()
        }
    }
    const handleMouseUp = (event: any) => {
        setDragging(false)
        const eventType = event.type.toLowerCase();
        if (eventType === 'mouseup') {
            event.stopPropagation()
            event.preventDefault()
        }
    }
    const handleMouseMove = (event: any)  => {
        if(getDragging) {
            let newLeft = 0;
            let newTop = 0;
            const eventType = event.type.toLowerCase();

            if (eventType !== 'mousemove') {
                newLeft = event.changedTouches[0].pageX - getImageRelPos.x;
                newTop = event.changedTouches[0].pageY - getImageRelPos.y;
             //   console.log(newLeft + " - " + newTop);
            } else if (eventType === 'mousemove') {
                newLeft = event.pageX - getImageRelPos.x;
                newTop = event.pageY - getImageRelPos.y;
            }
            event.preventDefault();
            event.stopImmediatePropagation();

            const borderLeft = (imgRef.current.width*getScale - imgRef.current.width)/2
            const borderRight = (imgRef.current.width*getScale - photoSize().w) - borderLeft
            let borderBottom = 0
            let borderTop  = (imgRef.current.height * getScale - imgRef.current.height) / 2

            if(imgRef.current.width >= imgRef.current.height) {
                borderBottom = imgRef.current.height / 2 - borderTop + 2;
            } else {
                borderBottom = ((-imgRef.current.height*getScale / 2) - borderTop + 106);
            }

            let topPosition = newTop;

            if (topPosition >= borderTop) {
               topPosition = borderTop;
            }

            if( (imgRef.current.height*getScale-photoSize().h) <= (borderTop-topPosition)) {
                topPosition = borderTop-(imgRef.current.height*getScale-photoSize().h);
            }


            if (newLeft > borderLeft && newLeft >= 0) {
                newLeft = borderLeft;
            } else if (newLeft < -borderRight) {
                newLeft = -borderRight;
            }

           // if (ne)

            //if((92/(getScale-getMinScale)) <= (borderTop-topPosition)) {
               // console.log("T: " + topPosition + " --- B: " + (imgRef.current.height / 2) + " --- BT: " + borderTop + "||" + getScale);
                //topPosition = (92/(getScale-getMinScale));
            //}

            // ((newTop <= borderTop && newTop > borderBottom) ? newTop : (newTop > borderBottom ? borderTop : borderBottom))
            setImagePosition({
                left: newLeft, //((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                top: topPosition,
            })
        }
        event.stopPropagation()
    }
    const handleSelectPhoto = (e: React.MouseEvent) => {
        e.preventDefault()
        fileRef.current.click()
    }
    const handleChangeImage = (event: any) => {
        stopStream();
        if(event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            const value = event.target.value
            const ext = value.substring(value.lastIndexOf('.') + 1).toLowerCase();
            if (ext == "png" || ext == "jpeg" || ext == "jpg") {
                // const image = new Image();
                // image.src = URL.createObjectURL(file);

                imgRef.current.setAttribute("src", URL.createObjectURL(file))
                imgRef.current.onload = () => {
                    //if(imgRef.current.width > imgRef.current.height) {
                        const scale = imgRef.current.width > imgRef.current.height ? photoSize().h/imgRef.current.height : 1;

                        setScale(scale);
                        setMinScale(scale);
                        const newLeft = 0;
                        //const newTop =  0;

                        const borderLeft = (imgRef.current.width*scale - imgRef.current.width)/2
                        let borderBottom = 0
                        let borderTop  = (imgRef.current.height * scale - imgRef.current.height) / 2

                        let leftCenter = 0;
                        if(imgRef.current.width >= imgRef.current.height) {
                            leftCenter = -(imgRef.current.width/2/4)+4
                        } else {
                            imgRef.current.style.width = '100%';
                        }
                        /*if(imgRef.current.width >= imgRef.current.height) {
                            borderBottom = imgRef.current.height / 2 - borderTop + 2;
                        } else {
                            borderBottom = ((-imgRef.current.height / 2) - borderTop + 106);
                        }*/

                        // ((newTop <= borderTop && newTop > borderBottom) ? newTop : (newTop > borderBottom ? borderTop : borderBottom))
                        setImagePosition({
                            left: leftCenter,//((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                            top: borderTop,
                        })
                }
                setSelectedPhoto(true);
            } else {
                alert(t("Nevybrali jste fotografii. Povolené přípony jsou PNG, JPG, JPEG"));
            }
        }
    }
    const handleScale = (scale: number) => {
        setScale(scale);

        let newLeft = getImagePosition.left;
        const newTop =  getImagePosition.top;

        const borderLeft = (imgRef.current.width*scale - imgRef.current.width)/2
        let borderBottom = 0
        let borderTop  = (imgRef.current.height * scale - imgRef.current.height) / 2
        const borderRight = (imgRef.current.width*scale - photoSize().w) - borderLeft

        /*
            if(imgRef.current.width >= imgRef.current.height) {
                borderBottom = imgRef.current.height / 2 - borderTop + 2;
            } else {
                borderBottom = ((-imgRef.current.height / 2) - borderTop + 106);
            }
        */

        let topPosition = newTop;

        if (topPosition >= borderTop) {
            topPosition = borderTop;
        }

        if( (imgRef.current.height*scale-photoSize().h) <= (borderTop-topPosition)) {
            topPosition = borderTop-(imgRef.current.height*scale-photoSize().h);
        }

        if (newLeft > borderLeft && newLeft >= 0) {
            newLeft = borderLeft;
        } else if (newLeft < -borderRight) {
            newLeft = -borderRight;
        }

        setImagePosition({
            left: newLeft,
            top: topPosition,
        })
    }
    const handleNextStep = async (e: any) => {
        setIsGenerating(true);
        e.preventDefault();

        if (window !== undefined) {
            window.localStorage.setItem('hugo_position', JSON.stringify({
                left: getImagePosition.left,
                top: getImagePosition.top,
                scale: getScale,
                imageUrl: imgRef.current.src.indexOf('cdn.storevio') >= 0 ? customer?.photo : imgRef.current.src,
            }));
        }

        if (imgRef.current.src.indexOf('cdn.storevio') >= 0) {
            const data = await convertToBase64(imgRef.current.src);
            generatePreview(
                data.image,
                imgRef.current.width * getScale,
                imgRef.current.height * getScale,
                getImagePosition.top - (imgRef.current.height * getScale - imgRef.current.height) / 2,
                getImagePosition.left - (imgRef.current.width * getScale - imgRef.current.width) / 2,
                photoSize().w,
                photoSize().h
            ).then((result: any) => {
                props.changeImage({
                    image: 'data:image/png;base64,' + result.image,
                    position: getImagePosition,
                    scale: getScale,
                })
                setIsGenerating(false);
                props.nextStep();
            }).catch((e: any) => {
                alert(t("Jejda, při tvoření náhledu nastala chyba"));
                setIsGenerating(false);
            })
        } else {
            const xhr = new XMLHttpRequest;
            xhr.responseType = 'blob';

            xhr.onload = function () {
                const recoveredBlob = xhr.response;
                const reader = new FileReader();
                reader.readAsDataURL(recoveredBlob);
                reader.onloadend = () => {
                    const base64String = reader.result;
                    if (typeof base64String === "string") {
                        const data = base64String.substr(base64String.indexOf(',') + 1).trim()
                        generatePreview(data,
                            imgRef.current.width * getScale,
                            imgRef.current.height * getScale,
                            getImagePosition.top - (imgRef.current.height * getScale - imgRef.current.height) / 2,
                            getImagePosition.left - (imgRef.current.width * getScale - imgRef.current.width) / 2,
                            photoSize().w, photoSize().h).then((result: any) => {
                            props.changeImage({
                                image: 'data:image/png;base64,' + result.image,
                                position: getImagePosition,
                                scale: getScale,
                            })
                            setIsGenerating(false);
                            props.nextStep();
                        }).catch((e: any) => {
                            alert(t("Jejda, při tvoření náhledu nastala chyba"));
                            setIsGenerating(false);
                        })
                    } else {
                        alert(t("Nelze konvertovat fotografii. Zkuste to znova."));
                        setIsGenerating(false);
                    }
                }
            }
            xhr.open('GET', imgRef.current.src);
            xhr.send();
        }
    }

    const handleOpenCamera = (e: any) => {
        if (e !== null) {
            e.preventDefault();
        }
        setIsLoadingCamera(true);
        setSelectedPhoto(false);
        const startVideo = () => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Not adding `{ audio: true }` since we only want video now
                navigator.mediaDevices.getUserMedia({video: true}).then(stream => {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                    return new Promise(resolve => videoRef.current.onplaying = resolve);
                }).then(function () {
                    //video.src = window.URL.createObjectURL(stream);
                   // const videoHeight = stream.getVideoTracks()[0].getSettings().height
                   // const videoWidth = stream.getVideoTracks()[0].getSettings().width

                    //videoRef.current.srcObject = stream;
                    videoRef.current.play();

                   // let interval = setInterval(() => {
                        if(videoRef.current.paused) return;
                    //videoRef.current.play().then(() => {
                        const videoHeight = videoRef.current.videoHeight
                        const videoWidth = videoRef.current.videoWidth
                        //alert( + " x " + videoRef.current.videoHeight)
                        console.log(videoWidth + "---" + videoHeight);
                        if (videoWidth > videoHeight) {
                            videoRef.current.style.minHeight = (photoSize().h) + 4 + "px";
                            videoRef.current.style.maxHeight = (photoSize().h) + 4 + "px";
                            videoRef.current.style.height = (photoSize().h) + 4 + "px";
                            videoRef.current.style.marginLeft = (-(videoWidth/2/4)+4) + "px"

                            // videoRef.current.style.minWidth = (photoSize().w * (videoWidth/videoHeight) + 4) + "px";
                            // videoRef.current.style.maxWidth = (photoSize().w * (videoWidth/videoHeight) + 4) + "px";
                            // videoRef.current.style.width = (photoSize().w * (videoWidth/videoHeight) + 4) + "px";
                            //videoRef.current.style.maxWidth = (photoSize().w * (videoWidth/videoHeight) + 4) + "px";
                            // alert(videoRef.current.style.minWidth)
                            //videoRef.current.style.left = "calc(50% - " + (videoRef.current.style.minWidth) +"px)";
                        } else {
                            videoRef.current.style.minWidth = (photoSize().w) + 4 + "px";
                            videoRef.current.style.maxWidth = (photoSize().w) + 4 + "px";
                            videoRef.current.style.width = (photoSize().w) + 4 + "px";

                            //   videoRef.current.style.minHeight = (photoSize().h * (videoHeight/videoWidth) + 4) + "px";
                            //   videoRef.current.style.maxHeight = (photoSize().h * (videoHeight/videoWidth) + 4) + "px";
                            //   videoRef.current.style.height = (photoSize().h * (videoHeight/videoWidth) + 4) + "px";
                            // videoRef.current.style.left = "calc(50% - " + (videoRef.current.style.minWidth) +"px)";

                            // alert("S: " + photoSize().h + " - " + videoRef.current.videoHeight+  " / " + videoRef.current.videoWidth)
                            // alert(videoRef.current.style.minHeight)
                            //alert(videoRef.current.videoHeight+  " / " + videoRef.current.videoWidth);
                        }

                        setOpenCamera(true);
                        setIsLoadingCamera(false);
                       // clearInterval(interval);
                    //});
                   // }, 300);

                   // if(videoWidth != undefined && videoHeight != undefined) {

                   // }


                   // alert(videoRef.current.videoWidth + "x" + videoRef.current.videoHeight)

                    // setTakenPhoto(false)

                });
            }
        }
        startVideo()



        //let h = photoSize().h//videoRef.current.videoHeight; //photoSize().w;

        /*videoInterval = setInterval(() => {
            if(videoRef.current === null) return;
            if(videoRef?.current?.paused) return;
            let ratio = (videoRef.current.videoWidth/videoRef.current.videoHeight);
            let w = videoRef.current.clientWidth//Math.ceil((photoSize().h)*ratio)+2;
            let h = videoRef.current.clientHeight//photoSize().h+2;

            canvasRef.current.width = w*20;
            canvasRef.current.height = h*20;

            let context = canvasRef.current.getContext('2d');
            if(context) {
                context.fillRect(0,0, canvasRef.current.width, canvasRef.current.height);
                context.translate(canvasRef.current.width, 0);
                context.scale(-1, 1);
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                const scale = Math.ceil(ratio*100)/100+0.01;
                setScale(scale);
                const newLeft = 0;
                const borderLeft = (w*scale - w)/2
                const borderTop  = ((h/scale) * scale - (h/scale)) / 2
                setImagePosition({
                    left: ((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                    top: borderTop,
                })
            }
        }, (1000/20));*/

    }

    const stopStream = () => {
        setIsLoadingCamera(false)
        setOpenCamera(false)

        const stream = videoRef.current.srcObject as MediaStream;

        if(stream) {
            const tracks = stream.getTracks();

            tracks.forEach(function (track: any) {
                track.stop();
            });

            videoRef.current.srcObject = null;
        }
    }

    const handleSnapshot = async (e: any) => {
        e.preventDefault();
        //let ratio = (videoRef.current.videoWidth/videoRef.current.videoHeight);
        let w = videoRef.current.clientWidth
        let h = videoRef.current.clientHeight
        //alert(w + " x " + h)
       // let w = Math.ceil((photoSize().h)*ratio);
       // let h = photoSize().h//videoRef.current.videoHeight; //photoSize().w;
          if (videoRef.current.videoWidth > videoRef.current.videoHeight) {
             // w = minHeight * ratio + 4; // 4 - je tolerance
             // h = minHeight + 4
          } else {
             // w = minWidth * ratio + 4; // 4 - je tolerance
             // h = minWidth + 4
          }

        canvasRef.current.width = w;
        canvasRef.current.height = h;

        /*
        alert(w);


        const scale = w > h ? 416/h : 1;

        setScale(scale);
        setMinScale(scale);
        const newLeft = 0;
        const borderLeft = (w*scale - w)/2
        let borderTop  = (h * scale - h) / 2
        setImagePosition({
            left: ((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
            top: borderTop,
        })*/

        //setTakenPhoto(true);
        let context = canvasRef.current.getContext('2d');
        if(context) {
            context.fillRect(0,0, w, h);
            context.translate(w, 0);
            context.scale(-1, 1);
            context.drawImage(videoRef.current, 0, 0, w, h);
            const imageData = canvasRef.current.toDataURL('image/png');
            const base64Response = await fetch(imageData);
            imgRef.current.setAttribute("src", URL.createObjectURL(await base64Response.blob()));
            imgRef.current.setAttribute("data-from-camera", "true");

            //imgRef.current.setAttribute("src", imageData)
            stopStream();
            setSelectedPhoto(true);


            //const scale = Math.ceil(ratio*100)/100+0.01;
            const scale = Math.ceil(w > h ? photoSize().h/h : 1);
            setScale(scale);
            setMinScale(scale);


            const newLeft = 0;
            const borderLeft = (w*scale - w)/2
            const borderTop  = ((h/scale) * scale - (h/scale)) / 2

            let leftCenter = 0;
            if(w >= h) {
                leftCenter = -(w/2/4)+4
            } else {
                imgRef.current.style.width = '100%';
            }

            setImagePosition({
                left: leftCenter, //((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                top: borderTop,
            })
            setOpenCamera(false);
            //clearInterval(videoInterval);

        }
    }

    const checkValidHugoImage = async (img: string) => {
        if (img === undefined || img === null || img === '') return false;
        if (img.indexOf("cdn.storevio") >= 0) return false
        try {
            const res = await fetch(img);
            return res.status === 200;
        } catch (e) {
            return false;
        }
    }

    // effects
    useEffect(() => {

        // window.addEventListener("touchstart", handleMouseDown)
        //window.addEventListener("touchstart", handleMouseDown)
        window.addEventListener('touchmove', handleMouseMove, { passive: false })
        window.addEventListener("touchend", handleMouseUp, { passive: false })

        window.addEventListener('mousemove', handleMouseMove, { passive: false })
        window.addEventListener('mouseup', handleMouseUp, { passive: false })

        return () => {
            //window.removeEventListener("touchstart", handleMouseDown)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener("touchend", handleMouseUp)

            window.removeEventListener('mousemove', handleMouseMove)
            // window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
        };
    }, [getDragging])

    useEffect(() => {

            const fetchData = async () => {
                let isValidHugoImage = false;
                let hugoPosition: any = null;
                let hugo: any = null
                if (window !== undefined) {
                    //const hugoImage: any = window.localStorage.getItem('hugo_image');
                    hugoPosition = window.localStorage.getItem('hugo_position');
                    hugo = JSON.parse(hugoPosition);
                    isValidHugoImage = await checkValidHugoImage(hugo?.imageUrl!);
                    if (isValidHugoImage) {
                        imgRef.current?.setAttribute("src", hugo.imageUrl);
                    } else if (customer?.photo) {
                        imgRef.current?.setAttribute("src", customer.photo.replace('/original/', '/1000x1000/'));
                    }
                }
                if ((customer?.photo || isValidHugoImage)) {

                    setSelectedPhoto(true);
                    if(imgRef.current !== null) {
                        imgRef.current.onload = () => {
                            if(imgRef.current.getAttribute("data-from-camera") === "true") return;
                            const scale = imgRef.current.width > imgRef.current.height ? photoSize().h / imgRef.current.height : 1;

                            if (window !== undefined) {

                                if (hugoPosition) {

                                    if (hugo.imageUrl === customer?.photo || isValidHugoImage) {
                                        setImagePosition({
                                            left: hugo.left,
                                            top: hugo.top,
                                        });
                                        setScale(hugo.scale);
                                        setMinScale(scale);
                                        if(imgRef.current.width < imgRef.current.height) {
                                            imgRef.current.style.width = '100%';
                                        }
                                    } else {
                                        setScale(scale);
                                        setMinScale(scale);
                                        const newLeft = 0;
                                        const borderLeft = (imgRef.current.width * scale - imgRef.current.width) / 2
                                        let borderTop = (imgRef.current.height * scale - imgRef.current.height) / 2
                                        setImagePosition({
                                            left: ((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                                            top: borderTop,
                                        })
                                        if(imgRef.current.width < imgRef.current.height) {
                                            imgRef.current.style.width = '100%';
                                        }
                                    }
                                } else {
                                    setScale(scale);
                                    setMinScale(scale);
                                    const newLeft = 0;

                                    const borderLeft = (imgRef.current.width * scale - imgRef.current.width) / 2
                                    let borderTop = (imgRef.current.height * scale - imgRef.current.height) / 2
                                    setImagePosition({
                                        left: ((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                                        top: borderTop,
                                    })
                                    if(imgRef.current.width < imgRef.current.height) {
                                        imgRef.current.style.width = '100%';
                                    }
                                }
                            } else {
                                setScale(scale);
                                setMinScale(scale);
                                const newLeft = 0;

                                const borderLeft = (imgRef.current.width * scale - imgRef.current.width) / 2
                                let borderTop = (imgRef.current.height * scale - imgRef.current.height) / 2
                                setImagePosition({
                                    left: ((newLeft <= borderLeft && newLeft > -borderLeft) ? newLeft : (newLeft > 0 ? borderLeft : -borderLeft)),
                                    top: borderTop,
                                })
                                if(imgRef.current.width < imgRef.current.height) {
                                    imgRef.current.style.width = '100%';
                                }
                            }
                        }
                    }
                } else {
                    setSelectedPhoto(false);
                }
            };


            fetchData().then();
       // setTakenPhoto(false)
        imgRef.current.setAttribute("data-from-camera", "false");
        stopStream();
    }, [displayModal, customer])

    useEffect(() => {
       if (props.selectPhoto) {
           fileRef.current.click()
       }
       if(props.openCamera) {
           handleOpenCamera(null)
       }
    }, [props.selectPhoto, props.openCamera])

    const photoSize = () => {
        return {
            w: refPhotoSelector?.current?.clientWidth,
            h: refPhotoSelector?.current?.clientHeight,
        }
    }



    return (
        <div className="modal-content-inner">

            <h2>{t("Virtuální zkoušeč Hugo")}</h2>
            <p>{t("HugoModalDesc")}</p>

           <div className="photo-wrap">
               <div className="photo-inner">
                   <div className={"photo-inner-main"} ref={refPhotoSelector}>
                       <div style={{overflow: 'hidden', position: 'relative', width: '100%', height: '100%'}}>
                         <img ref={imgRef}
                              style={{display: isSelectedPhoto ? 'block' : 'none', height: 'auto', transform: `translate3d(${getImagePosition.left}px, ${getImagePosition.top}px, 0px) scale(${getScale})`, width: 'auto',}} />
                           {isLoadingCamera && <p style={{marginTop: '20px'}}>{t("startingCamera")}</p>}

                           <video ref={videoRef} style={{zIndex: 1, transform: 'rotateY(180deg)', display: isOpenCamera ? 'block' : 'none'}} playsInline autoPlay loop disablePictureInPicture muted />
                       </div>
                       {(isSelectedPhoto || isOpenCamera) && <div className="photo-selector" onTouchStart={handleMouseDown} onMouseDown={handleMouseDown}>
                           {isSelectedPhoto && <span className="photo-selector-arrows" />}
                           <span className="photo-selector-face" />
                       </div>}
                       <div className={cn(["rc-slider-small", style.slider, {[style.displayNone]: !isSelectedPhoto }])} >
                           <Slider min={getMinScale} value={getScale} max={4} step={0.001} onChange={handleScale} />
                       </div>
                   </div>


                   <input type="file" onChange={handleChangeImage} ref={fileRef} style={{display: 'none'}} accept="image/x-png,image/jpeg" />
               </div>

               <div className={"photo-buttons"}>
                       <a href="#" onClick={handleSelectPhoto} className={cn('photo-inner-select', style.actions)}>{t("Vybrat foto")}</a>

                       {!isOpenCamera && <a href="#" onClick={handleOpenCamera} className={cn('photo-inner-take', style.actions)}>{t("Spustit kameru")}</a>}
                       {isOpenCamera && <a href="#" onClick={handleSnapshot} className={cn('photo-inner-take', style.actions)}>{t("Vyfotit")}</a>}
               </div>
           </div>

           {isSelectedPhoto &&
               <Button
                   className={"btn btn-background"}
                   loading={isGenerating}
                   disabled={isGenerating}
                   Component={'a'}
                   href={"#"}
                   onClick={handleNextStep}
               >{t("Pokračovat")}</Button>}
           <p>{t("Chcete si klobouk vyzkoušet?")} <a href="">{t("Navštivte naše prodejny")}</a></p>

            <canvas ref={canvasRef}
                    style={{ display: 'none'}} />
       </div>
    );
}
