import {fetcher} from "@framework/fetcher";

const registerCustomer = async (body: {email: string, password: string, agreeWithConditions: boolean}) => {
    return await fetcher({
        url: `/customers`,
        method: 'POST',
        body
    });
}
export default registerCustomer
