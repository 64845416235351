import {FC, ReactElement, useEffect, useState} from 'react';
import cn from 'classnames'
import {useUI} from "@components/ui";
import {giftBoxColors} from "@lib/giftBoxColors";
import useTranslation from "next-translate/useTranslation";

const GiftModal = () => {

    const {t} = useTranslation("common")
    const {setGiftColor, displayModal, closeModal} = useUI()
    const [currentBox, setCurrentBox] = useState<any>(giftBoxColors()[0]);

    useEffect(() => {
        if (displayModal) {
            setGiftColor(null)
        }
    }, [displayModal])
    const handleChangeColor = (box: any) => {
        setCurrentBox(box)
    }

    const handleSave = () => {
        setGiftColor(currentBox);
        closeModal();
    }

    return (<>
            <div className={"content-inner"}>
            <h2>{t("GiftModalTitle")}</h2>
            <p>{t("GiftModalDesc")}</p>
            <div className="gift-tabs">
                <div className="gift-tab active">
                    <img src={currentBox.image} alt="" />
                </div>
            </div>
            <div className="gift-tabs-nav">
                {giftBoxColors().map((box: any, index: number) => {
                    return <a href="#" key={index} onClick={(e) => { e.preventDefault(); handleChangeColor(box); }} className={cn({['active']: box.color === currentBox.color })} style={{background: box.color}} />
                })}

            </div>
            <div className="btns">
                <a href="#" onClick={handleSave} className="btn btn-background">
                    {t("GiftSaveBtn")}
                </a>
            </div>
        </div>
    </>);
}
export default GiftModal
