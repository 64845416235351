import {fetcher} from "@framework/fetcher";

const generatePreview = async (image: string, width: number, height: number, top: number, left: number, boxWidth: number, boxHeight: number) => {
    return await fetcher({
        method: 'POST',
        url: `/tester/preview`,
        body: {
            image,
            width,
            height,
            top,
            left,
            boxWidth,
            boxHeight,
        }
    });
}
export default generatePreview
