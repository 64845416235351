import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const getTested = async (locale: string, page: number, auth?: string) => {
    const token = auth ? auth : Cookies.get(CUSTOMER_COOKIE)
    return await fetcher({
        url: `/my-account/tested?lang=${locale}&page=${page}`,
        method: 'GET',
        headers: {
            'authorization': 'bearer ' + token
        }
    });
}
export default getTested
