import {useState} from "react";
import {useUI} from "@components/ui";
import useCustomer from '@framework/customer/use-customer';
import cn from 'classnames';
import useTranslation from "next-translate/useTranslation";
// import addFavorite from "@framework/api/endpoints/myAccount/addFavorite";
// import removeFavorite from "@framework/api/endpoints/myAccount/removeFavorite";
import {useWishlist, useAddWishlist, useRemoveWishlistItem} from "@framework/wishlist";
import {useRouter} from "next/router";


export default function MarkFavorite({...props}) {

    const {t} = useTranslation('common')
    const router = useRouter();
    const { data } = useWishlist({ locale: router.locale! })
    const addItem = useAddWishlist()
    const removeItem = useRemoveWishlistItem()
    const { data: customer } = useCustomer()
    const [getFavorite, setFavorite] = useState(false);
    const { openSidebar, setSidebarView } = useUI()
    const [loading, setLoading] = useState(false)

    // @ts-ignore Wishlist is not always enabled
    const itemInWishlist = data?.find(
        // @ts-ignore Wishlist is not always enabled
        // (item) => item.product.id === props.id
        (item) => item.id === props.id
    )

    const handleWishlistChange = async (e: any) => {
        e.preventDefault()

        if(!customer) {
            setSidebarView('LOGIN_VIEW')
            openSidebar()
            return;
        }

        setFavorite(!getFavorite);

        if(itemInWishlist) {
            removeItem({
                productId: props.id
            });
        } else {
            addItem({
                productId: props.id
            });
        }
    }

    return (
        <a href={"#"}
           onClick={handleWishlistChange}
           className={cn(['product-card-favorite', {
               'btn-favorite': props.isDetail,
               'pcf-ok': itemInWishlist
           }])}
           title={t("Přidat do oblíbených")}
        >{props.isDetail ? t('Přidat do oblíbených') : ''}</a>
    );
}
