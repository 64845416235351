import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";


const getProducts = async (
    locale: string | undefined,
    page: number,
    categoryId?: string | null,
    orderBy?: { sort: string, order: 'ASC' | 'DESC' } | null,
    filter?: { priceFrom: number, priceTo: number,  parameters: string[], variants: string[], tag: string[], branchOffice: string[] } | null,
    q?: string | null,
    auth?: string) => {
    const customerToken = auth ? auth : Cookies.get(CUSTOMER_COOKIE);
    if (categoryId) {
        return await fetcher({
            locale,
            query: {
                categoryId,
                priceFrom: filter?.priceFrom !== undefined ? filter.priceFrom : '',
                priceTo: filter?.priceTo !== undefined ? filter.priceTo : '',
                sort: orderBy?.sort !== undefined ? orderBy.sort : '',
                order: orderBy?.order !== undefined ? orderBy.order : '',
                parameters: filter?.parameters !== undefined ? filter.parameters : '',
                variants: filter?.variants !== undefined ? filter.variants : '',
                branchOffice: filter?.branchOffice !== undefined ? filter.branchOffice : '',
                tag: filter?.tag !== undefined ? filter.tag : '',
                q: q ? q : '',
                page: isNaN(page) ? 1 : page,
            },
            headers: {
                'Authorization': 'Bearer ' + customerToken,
            },
            url: `/products`,
        });
    } else {
        return await fetcher({
            locale,
            query: {
                priceFrom: filter?.priceFrom !== undefined ? filter.priceFrom : '',
                priceTo: filter?.priceTo !== undefined ? filter.priceTo : '',
                sort: orderBy?.sort !== undefined ? orderBy.sort : '',
                order: orderBy?.order !== undefined ? orderBy.order : '',
                parameters: filter?.parameters !== undefined ? filter.parameters : '',
                variants: filter?.variants !== undefined ? filter.variants : '',
                branchOffice: filter?.branchOffice !== undefined ? filter.branchOffice : '',
                tag: filter?.tag !== undefined ? filter.tag : '',
                q: q ? q : '',
                page: isNaN(page) ? 1 : page,
            },
            headers: {
                'Authorization': 'Bearer ' + customerToken,
            },
            url: `/products`,
        });
    }
}
export default getProducts
