import React, {FC, ReactElement, useEffect, useState} from 'react';
import cn from 'classnames'
import RecommendedProducts from "@components/recommendedProducts";
import useTranslation from "next-translate/useTranslation";
import {useRouter} from "next/router";
import storevio from "@lib/api";
import Product from "@components/product";
import Hugo from "@components/product/hugo/hugo";
import {useUI} from "@components/ui";

const HugoProducts = ({...props}) => {

    const { t } = useTranslation('common');
    const [products, setProducts] = useState<any>([]);
    const [openHugo, setOpenHugo] = useState<boolean>(false);
    const router = useRouter()
    const {setHugoUrl, displayModal, setHugoProductId} = useUI()

    useEffect(() => {
        async function fetchData() {
            setProducts(await storevio.getProductsTester(router.locale!))
        }
        fetchData().then();
    }, [router.locale])

    useEffect(() => {
        setOpenHugo(false);
    }, [displayModal])

    const handleOpenHugo = (id: string, testerPhoto: string) => {
        if (testerPhoto) {
            setHugoUrl(testerPhoto);
            setHugoProductId(id);
            setOpenHugo(true);
        } else {
            alert(t("U produktu nebo varianty není HUGO zkoušeč dostupný."))
        }
    }

    if (openHugo) {
        return <Hugo />
    }

    return (<>
        <div className="modal-content-inner" style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <h2>{t("Virtuální zkoušeč Hugo")}</h2>
            <p>{t("HugoModalDesc")}</p>

            <section className="products-section products-hugo">
                <div className={"products"}>
                    <div className="products-wrap">
                        {products?.map((row: any) => <Product
                            product={row.product}
                            key={row.id}
                            id={row.product.id}
                            slug={row.product.slug}
                            name={row.product.name}
                            tags={row.product.tags}
                            options={props.options}
                            price={row.product.price}
                            hasVariants={row.product.hasVariants}
                            variants={row.product.variants}
                            image={row.product.mainPhoto}
                            testerPhoto={row.product.testerPhoto}
                            openHugo={handleOpenHugo}
                        />)}
                    </div>
                </div>
            </section>
        </div>
       </>
    );
}
export default HugoProducts
