import React, {useEffect, useRef, useState} from "react";
import Loader from "@components/common/Loader";

export default function ImageModel(props: { src: string; width: number; height: number; alt?: number; ref?: any, onMouseDown?: any, fit?: string }) {
    // const { t } = useTranslation('common');

    const [loaded, setLoaded] = useState<boolean>(false);

    let src = props.src;

    if (props.fit) {
        src = props.src?.replace("/original/", `/${props.width}x${props.height}_${props.fit}/`);
    } else {
        src = props.src?.replace("/original/", `/${props.width}x${props.height}/`);
    }

    const imageRef = useRef() as React.MutableRefObject<HTMLImageElement>

    useEffect(() => {
        const img = new Image()
        img.onload = function () {
            setLoaded(true)
        }
        img.src = src;
        imageRef.current = img;
    });


    useEffect(() => {
        if (loaded) {
            setLoaded(false)
        }
    }, [src])

    /*
    useEffect(() => {
        console.log("IMG" + src)
        //alert(imageRef?.current?.complete)
        if(imageRef?.current) {
            imageRef.current.onload = () => {
                setLoaded(true);
                console.log("IMG" + src)
                //alert(src + " - loaded")
            } // assign before src
        }
    }, [imageRef?.current])*/


    return <>
        {!loaded && <>
            <div style={{width: '100%', height: props.height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Loader />
            </div>
            <img ref={imageRef} src={src} style={{width: 1, height: 1}} />
        </>}
        {loaded && <img src={src} />}
    </>;
}
