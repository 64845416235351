import {useForm} from "react-hook-form";
import * as yup from "yup";
import {useRouter} from "next/router";
import {yupResolver} from "@hookform/resolvers/yup";
import useTranslation from "next-translate/useTranslation";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {Button, useUI} from "@components/ui";
import storevio from "@lib/api";
import intlTelInput from "intl-tel-input";
import useCustomer from "@framework/customer/use-customer";
import Link from "next/link";


const BuyVoucher = ({ ...props }) => {
    const { t } = useTranslation('common');
    const schema = yup.object().shape({
        reaching: yup.string().nullable().required(t("Vyberte jak vás máme oslovovat")),
        firstname: yup.string().nullable().required(t("Jméno je povinné")),
        lastname: yup.string().nullable().required(t("Příjmení je povinné")),
        email: yup.string().nullable().email(t("Zadejte platnou e-mailovou adresu")).required(t("Emailová adresa je povinná")),
        //phonePrefix: yup.string().required("Vyberte předvolbu"),
        phoneNumber: yup.string().nullable().required(t("Telefonní číslo je povinné")),
        street: yup.string().nullable().required(t("Ulice je povinná")),
        houseNumber: yup.string().nullable().required(t("Čp je povinné")),
        city: yup.string().nullable().required(t("Město je povinné")),
        zipCode: yup.string().nullable().required(t("PSČ je povinné")),
        country: yup.string().nullable().required(t("Vyberte zemi")),
        companyName: yup.string().nullable().when("personType", {
            is: 'po',
            then: yup.string().required(t("Název společnosti je povinný"))
        }),
        identificationNumber: yup.string().nullable().when("personType", {
            is: 'po',
            then: yup.string().required(t("IČ je povinný"))
        }),
        condition: yup.bool() // use bool instead of boolean
            .oneOf([true], t("Musíte souhlasit s obchodními podmínkami")),
    });
    const router = useRouter()
    const { data: customer, isLoading: isLoadingCustomer, error: customerError } = useCustomer();
    const [countries, setCountries] = useState([]);
    const [windowHeight, setWindowHeight] = useState(400);
    const { productId, closeModal, displayModal } = useUI()
    const {register, reset, setValue, handleSubmit, watch, formState: { isSubmitting, errors }} = useForm({ resolver: yupResolver(schema) });
    const watchPersonType = watch('personType', 'fo')

    const fetchData = async () => {
        const countries = await storevio.getDeliveryCountries(router.locale!);
        setCountries(countries);
    }

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
     setWindowHeight(window.innerHeight - 100);
     reset();
     setValue('personType', customer ? customer.personType : 'fo');
    }, [displayModal, customer])



    useEffect(() => {
        const input = document.querySelector("#phone");
        if (input) {
            intlTelInput(input, {
                initialCountry: "cz",
                preferredCountries: ["cz", "sk"],
                separateDialCode: true
            });
            const iti = window.intlTelInputGlobals.getInstance(input);

            if(customer) {
                iti.setNumber(customer?.phonePrefix);
            } else {
                iti.setNumber(`+${iti.getSelectedCountryData().dialCode}`);
            }

            input.addEventListener("countrychange", function() {
                setValue('phonePrefix', `+${iti.getSelectedCountryData().dialCode}`);
            });
        }
        if(customer) {
            reset({
                personType: customer.personType,
                reaching: customer.reaching,
                firstname: customer.firstname,
                lastname: customer.lastname,
                companyName: customer.companyName,
                identificationNumber: customer.identificationNumber,
                vatNumber: customer.vatNumber,
                email: customer.email,
                phonePrefix: customer.phonePrefix,
                phoneNumber: customer.phoneNumber,
                street: customer.street,
                houseNumber: customer.houseNumber,
                city: customer.city,
                zipCode: customer.zipCode,
                country: customer?.country?.id
            });
        }
    }, [customer, countries]);

    const onSubmit = async (body: any) => {
       const order = await storevio.createVoucherOrder({
           paymentType: body.paymentType,
           giftFor: body.giftFor,
           reaching: body.reaching,
           firstname: body.firstname,
           lastname: body.lastname,
           email: body.email,
           phonePrefix: body.phonePrefix,
           phoneNumber: body.phoneNumber,
           personType: body.personType,
           companyName: body.personType === 'po' ? body.companyName : null,
           identificationNumber: body.personType === 'po' ? body.identificationNumber : null,
           vatNumber: body.personType === 'po' ? body.vatNumber : null,
           street: body.street,
           houseNumber: body.houseNumber,
           city: body.city,
           zipCode: body.zipCode,
           country: body.country,
           productId
       }, router.locale!, props.options.currency.id);


        await router.push("/cart/done?order=" + order.number + "&payment-code=" + body.paymentType);
        closeModal();

    }

    return (<>
        <h2>{t("Koupit")}</h2>
        <div style={{overflow: 'auto', height: `${windowHeight}px`}}>
        <form onSubmit={handleSubmit(onSubmit)} className={"buy-form"}>

            <div className="form-wide">
                <label>Platba</label>
                <select {...register("paymentType")}>
                    <option value={"4f39d993-3297-4603-8f44-44f1db90e725"}>{t("gift_online")}</option>
                    <option value={"29d56eb2-50ce-49e9-8431-d6a2aedb6c02"}>{t("bank_transfer")}</option>
                </select>
            </div>
            <div className="form-wide">
                <label>
                    {t("modal_window_gift_for")}
                </label>
                <input type="text" {...register("giftFor")} className={cn({"error-input": errors.giftFor})} />
                <em style={{fontSize: '0.9rem'}}>{t("gift_modal_help")}</em>
                {errors.giftFor && <span className="error-item">{errors.giftFor?.message}</span>}
            </div>

                <h3>{t("Osobní údaje")}</h3>

                <div className="form-wide">
                    <label>
                        {t("Oslovení")}
                    </label>
                    <select {...register("reaching")} className={cn({"error-input": errors.reaching})}>
                        <option value={""}>{t("selectPromt")}</option>
                        <option value={"ms"}>{t("Paní")}</option>
                        <option value={"mr"}>{t("Pan")}</option>
                    </select>
                    {errors.reaching && <span className="error-item">{errors.reaching?.message}</span>}
                </div>
                <div className="form-wide">
                    <label>
                        {t("Vaše jméno")}
                    </label>
                    <input type="text" {...register("firstname")} className={cn({"error-input": errors.firstname})} />
                    {errors.firstname && <span className="error-item">{errors.firstname?.message}</span>}
                </div>
                <div className="form-wide">
                    <label>
                        {t("Vaše příjmení")}
                    </label>
                    <input type="text" {...register("lastname")} className={cn({"error-input": errors.lastname})} />
                    {errors.lastname && <span className="error-item">{errors.lastname?.message}</span>}
                </div>

                <div className="form-wide">
                    <label>
                        {t("Váš e-mail")}
                    </label>
                    <input type="email" {...register("email")} className={cn({"error-input": errors.email})}/>
                    {errors.email && <span className="error-item">{errors.email?.message}</span>}
                </div>
                <div>
                    <div className="form-third">
                        <label>
                            {t("Váš telefon")}
                        </label>
                        <input type="text" id={"phone"} {...register("phoneNumber")} className={cn({"error-input": errors.phoneNumber})} />
                        {errors.phoneNumber && <span className="error-item">{errors.phoneNumber?.message}</span>}
                    </div>
                </div>

            <div className="account-body-block">
                <h3>{t("Fakturační údaje")}</h3>
                <div className="form-wide form-wide-company-selector">
                    <input type="radio" id="r1" {...register("personType")} value={"fo"} className="company-hide"/>
                    <label htmlFor="r1">
                        {t("Na soukromou osobu")}
                    </label>
                    <input type="radio" {...register("personType")} id="r2" value={"po"} className="company-show"/>
                    <label htmlFor="r2">
                        {t("Na firmu")}
                    </label>
                </div>
                {watchPersonType === 'po' && <div className="company-items">
                    <div className="form-wide">
                        <label>
                            {t("Název společnosti")}
                        </label>
                        <input type="text" {...register("companyName")} className={cn({"error-input": errors.companyName})}/>
                        {errors.companyName && <span className="error-item">{errors.companyName?.message}</span>}
                    </div>
                    <div>
                        <div className="form-side">
                            <label>
                                {t("IČ")}
                            </label>
                            <input type="text" {...register("identificationNumber")} className={cn({"error-input": errors.identificationNumber})}/>
                            {errors.identificationNumber && <span className="error-item">{errors.identificationNumber?.message}</span>}
                        </div>
                        <div className="form-side">
                            <label>
                                {t("DIČ")}
                            </label>
                            <input type="text" {...register("vatNumber")} className={cn({"error-input": errors.vatNumber})}/>
                            {errors.vatNumber && <span className="error-item">{errors.vatNumber?.message}</span>}
                        </div>
                    </div>
                </div>}
                <div>
                    <div className="form-third-r">
                        <label>
                            {t("Ulice")}
                        </label>
                        <input type="text" {...register("street")} className={cn({"error-input": errors.street})} />
                        {errors.street && <span className="error-item">{errors.street?.message}</span>}
                    </div>
                    <div className="form-third-r">
                        <label>
                            {t("Číslo popisné")}
                        </label>
                        <input type="text" {...register("houseNumber")} className={cn({"error-input": errors.houseNumber})}  />
                        {errors.houseNumber && <span className="error-item">{errors.houseNumber?.message}</span>}
                    </div>
                </div>
                <div>
                    <div className="form-third-r">
                        <label>
                            {t("Město")}
                        </label>
                        <input type="text" {...register("city")} className={cn({"error-input": errors.city})}  />
                        {errors.city && <span className="error-item">{errors.city?.message}</span>}
                    </div>
                    <div className="form-third-r">
                        <label>
                            {t("PSČ")}
                        </label>
                        <input type="text" {...register("zipCode")}  className={cn({"error-input": errors.zipCode})} />
                        {errors.zipCode && <span className="error-item">{errors.zipCode?.message}</span>}
                    </div>
                </div>
                <div className="form-wide">
                    <label>
                        {t("Stát")}
                    </label>
                    <select {...register("country")} className={cn({"error-input": errors.country})}>
                        <option value={""}>{t("select")}</option>
                        {countries?.map((country: any, index: number) => <option key={index} value={country.id}>{country.name}</option>)}
                    </select>
                    {errors.country && <span className="error-item">{errors.country?.message}</span>}
                </div>
                <div className="form-wide">
                    <input type="checkbox" {...register('condition')} id="agree" />
                    <label htmlFor="agree"
                           dangerouslySetInnerHTML={{__html: t('condition')}} />
                    {errors.condition && <span className="error-item">{errors.condition?.message}</span>}
                </div>

            </div>


            <div className="form-wide form-wide-last">
                <Button
                    className={"btn btn-background"}
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                >
                    {t("Dokončit objednávku")}
                </Button>
            </div>
            <p style={{fontSize: 11, textAlign: "left", paddingLeft: 0, paddingRight: 0}}>{t("gift_modal_customer_info")}</p>

        </form>
        </div>
        </>);
}
export default BuyVoucher
