import useCustomer from "@framework/customer/use-customer";
import {formatPrice, formatNumber} from "@lib/formatPrice";
import useSettings from "@framework/settings/use-settings";
import {useEffect, useState} from "react";
import useTranslation from "next-translate/useTranslation";


export default function Price({...props}) {
    const { data: customer, isLoading: customerLoading } = useCustomer();
    const { data: settings, isLoading: isLoadingSettings } = useSettings();
    const { t } = useTranslation('common');
    const [showPrice, setShowPrice] = useState<string | null>(null);
    const [priceOption, setPriceOption] = useState<any>(props.priceOption);
    const [isWholesale, setWholesale] = useState<boolean>(false);

    let priceWithoutVat = props.amountWithoutVat;
    let priceWithVat = props.amount;
    let quantity = props.quantity ?? 1;
    let actionPriceWithoutVat = props.actionPrice?.amountWithoutVat;
    let actionPriceWithVat = props.actionPrice?.amount;

    if (props.showOriginalIfExistDiscount === undefined) {
        props.showOriginalIfExistDiscount = true;
    }

    useEffect(() => {
        let deliveryPrice = props.deliveryPrice;
        let paymentPrice = props.paymentPrice;
        if (!isLoadingSettings && !customerLoading) {
            console.log(customer);
            if (customer) {
                if (customer?.group === null) {
                    setShowPrice(settings?.price.show);
                    if(paymentPrice !== undefined && deliveryPrice !== undefined && showPrice && settings?.price.show.indexOf('WITHOUT_') > -1) {
                        deliveryPrice = deliveryPrice/1.21;
                        paymentPrice = paymentPrice/1.21;
                    }
                } else {
                    setShowPrice(customer?.group.priceList.showPrice);
                    setWholesale(customer?.group.id === '57c3dcc3-fd8a-4a81-9084-06814a85528a');
                    if(paymentPrice !== undefined && deliveryPrice !== undefined && showPrice && customer?.group.priceList.showPrice?.indexOf('WITHOUT_') > -1) {
                        deliveryPrice = deliveryPrice/1.21;
                        paymentPrice = paymentPrice/1.21;
                    }
                }

            } else {
                setShowPrice(settings?.price.show);
            }
        }

        console.log(deliveryPrice);
        if (props.recalculate === false) {
            setPriceOption({...priceOption, symbolAfter: props.priceOption.symbolAfter, exchangeRate: 1, deliveryPrice, paymentPrice});
        } else {
            setPriceOption({...priceOption, symbolAfter: props.priceOption.symbolAfter,  exchangeRate: props.priceOption.exchangeRate, deliveryPrice, paymentPrice});
        }



    }, [props.priceOption, customerLoading, customer, isLoadingSettings, settings, props.deliveryPrice, props.paymentPrice]);

    if (showPrice === 'WITH_VAT_LABEL') {

        if (actionPriceWithVat > 0) {
            return <>
                <span title={formatPrice(actionPriceWithoutVat, quantity, priceOption, true, isWholesale)}>
                    {formatPrice(actionPriceWithVat, quantity, priceOption, undefined, isWholesale)}
                </span>
                {props.showOriginalIfExistDiscount && <del style={{marginLeft: '5px', fontSize: '10px'}}>{formatPrice( priceWithVat, quantity, priceOption, undefined, isWholesale)}</del>}
                {props.showLabel && <small className={"price-label"}>{t('s DPH')}</small>}
            </>
        }

        return <>
            <span title={formatPrice(priceWithoutVat, quantity, priceOption, true, isWholesale)}>
                {formatPrice(priceWithVat, quantity, priceOption, undefined, isWholesale)}
            </span>
            {props.showLabel && <small className={"price-label"}>{t('s DPH')}</small>}
        </>
    }
    if (showPrice === 'WITHOUT_VAT') {
        if (actionPriceWithoutVat > 0) {
            return <>
                {formatPrice(actionPriceWithoutVat, quantity, priceOption, undefined, isWholesale)}
                {props.showOriginalIfExistDiscount && <del style={{marginLeft: '5px', fontSize: '10px'}}>{formatPrice( priceWithoutVat, quantity, priceOption, undefined, isWholesale)}</del>}
                {props.showLabel && <small className={"price-label"}>{t('bez DPH')}</small>}
            </>
        }

        return <>
            {formatPrice(priceWithoutVat, quantity, priceOption, undefined, isWholesale)}
            {props.showLabel && <small className={"price-label"}>{t('bez DPH')}</small>}
        </>
    }

    if (showPrice === 'WITHOUT_VAT_LABEL') {
        if (actionPriceWithoutVat > 0) {
            return <>
                <span title={formatPrice(actionPriceWithVat, quantity, priceOption, true,  isWholesale)}>
                    {formatPrice(actionPriceWithoutVat, quantity, priceOption, undefined, isWholesale)}
                </span>
                {props.showOriginalIfExistDiscount && <del style={{marginLeft: '5px', fontSize: '10px'}}>{formatPrice( priceWithoutVat,quantity, priceOption, undefined, isWholesale)}</del>}
                {props.showLabel && <small className={"price-label"}>{t('bez DPH')}</small>}
            </>
        }

        return <>
            <span title={formatPrice(priceWithVat, quantity, priceOption, true, isWholesale)}>
                {formatPrice(priceWithoutVat, quantity, priceOption, undefined, isWholesale)}
            </span>
            {props.showLabel && <small className={"price-label"}>{t('bez DPH')}</small>}
        </>
    }


   /* return <>
        {formatPrice(priceWithVat, props.priceOption)}
    </>*/

    if (props?.actionPrice?.amount) {
        return <>
          {formatPrice(props?.actionPrice.amount, quantity, priceOption, undefined, isWholesale)}
          {props.showOriginalIfExistDiscount && <del style={{marginLeft: '5px', fontSize: '10px'}}>{formatPrice( priceWithVat,quantity, priceOption, undefined, isWholesale)}</del>}
        </>
    }

    return <>{formatPrice(priceWithVat, quantity, priceOption, undefined, isWholesale)} </>;

    //  {props?.actionPrice?.amount && <del style={{marginLeft: '5px', fontSize: '10px'}}>{formatPrice( props.actionPrice.amount, props.priceOption)}</del>}
}
