import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const saveToTested = async (productId: string,
                            image: string,
                            hat: string,
                            hatWidth: number,
                            hatHeight: number,
                            top: number,
                            left: number) => {
    const token = Cookies.get(CUSTOMER_COOKIE)
    if (!token) {
        return null
    }

    return await fetcher({
        method: 'POST',
        url: `/tester/${productId}`,
        headers:  {
            'Authorization': 'Bearer ' + token
        },
        body: {
            image,
            hat,
            hatWidth,
            hatHeight,
            top,
            left
        }
    });
}
export default saveToTested
