import {FC, ReactElement, useEffect, useState} from 'react';
import cn from 'classnames'
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";

const TableSize = () => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const [lang, setLang] = useState(router.locale);

    useEffect(() => {
        setLang(router.locale);
    }, [router.locale])

    return (<>

                <div className={"content-inner"}>
                    <div style={{maxHeight: '400px', textAlign: 'left', padding: '30px'}}>

                        {(lang === 'cs') ? <>
                            <h1 style={{fontSize: '2.7rem'}}>Jak určíte správnou velikost klobouku?</h1>
                            <h2>1. Změřte si obvod hlavy</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Klasicky krejčovským metrem. Měřte vždy kolem hlavy nad ušima, v místech, kde budete
                                chtít klobouk nosit – kolem nejširší části hlavy. Měření pro jistotu zopakujte. Metr příliš
                                neutahujte.</p>

                            <h2>2. Výběr velikosti klobouků, čepic a baretů</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Pokud se při měření trefíte mezi dvě čísla (např. 58,5cm), objednávejte si vždy klobouk
                                větší (v našem případě velikost 59). Větší velikost klobouku se dá jednoduše upravit na
                                menší vložením slabého proužku molitanu nebo podobného materiálu pod potní pásek.
                                Zmenšující potní pásek Vám rádi na vyžádání poskytneme zdarma na naší prodejně
                                nebo jej přibalíme do Vaší objednávky.</p>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Pro správné určení velikosti pletených pokrývek hlavy, jako jsou čepice a barety, prosím
                                využijte převodní tabulku velikostí níže. Určující je obvod hlavy. Pro čepice použijte
                                převod na sdružené velikosti XS, S ,M, L, XL. Pro výběr velikosti baretů použijte převod v
                                palcích 9&quot; – 12,5&quot;.</p>

                            <h2>3. Univerzální velikost klobouků</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Pokud je velikost výrobku uvedena jako univerzální, klobouk je vybaven elastickým
                                potním páskem, a je vhodný pro velikost hlavy od 56cm do 60cm. U sdružených velikostí
                                S, M, L, XL si vyhledejte správnou velikost klobouku v uvedené tabulce velikostí:</p>
                            <h2>4. Univerzální velikosti čepic a baretů</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Pokud je u pletených pokrývek hlavy uvedena velikost N nebo ONE SIZE, jedná se o
                                univerzální velikost, která nejlépe padne velikostem 55 – 60.</p>


                        </> : <><h1 style={{fontSize: '2.7rem'}}>How do you identify the correct hat size?</h1>
                            <h2>1. Measure the circumference of your head</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>Measure the circumference of your head using a tailor&#39;s tape measure. Always
                                measure around the head above the ears, at the point where you would like to
                                wear the hat - around the widest part of the head. Repeat the measurement for
                                accuracy. Do not tighten the tape measure too much.</p>

                            <h2>2. Selecting the size of hats, caps, and beret</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>If your measurement falls between two sizes (e.g., 58.5cm), always order the
                                larger size (in this case, size 59). A larger hat size can be easily adjusted to a
                                smaller size by inserting a thin strip of foam or similar material under the
                                sweatband. We are happy to provide you with a reducing sweatband for free at
                                our store upon request, or we can include it in your order.</p>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>For determining the correct size of knitted headwear, such as caps and berets,
                                please refer to the size conversion table below. The head circumference is the
                                determining factor. For caps, use the conversion to combined sizes XS, S, M,
                                L, XL. For selecting beret sizes, use the conversion in inches from 9&quot; to 12.5&quot;.</p>

                            <h2>3. Universal hat size</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>If the product size is listed as universal, the hat is equipped with an elastic
                                sweatband and is suitable for head sizes from 56cm to 60cm. For combined
                                sizes S, M, L, XL, please refer to the provided size chart to find the correct hat
                                size.</p>
                            <h2>4. Universal sizes for caps and berets</h2>
                            <p style={{paddingTop: '0', paddingBottom: '5px'}}>If the knitted headwear is labeled as size N or ONE SIZE, it is a universal size
                                that best fits sizes 55 to 60.</p>



                            </>}


                    <h2>{t("Tabulka velikostí")}</h2>
            <table>
                <tr>
                    <td style={{width: '33.33%'}}>XS</td>
                    <td style={{width: '33.33%'}}>52 – 53 cm</td>
                    <td style={{width: '33.33%'}}>9&quot; - 9,5&quot;</td>
                </tr>
                <tr>
                    <td>S</td>
                    <td>54 – 55 cm</td>
                    <td>10,5&quot; - 11&quot;</td>
                </tr>
                <tr>
                    <td>M</td>
                    <td>56 – 57 cm</td>
                    <td>11&quot; - 11,5&quot;</td>
                </tr>
                <tr>
                    <td>L</td>
                    <td>58 – 59 cm</td>
                    <td>12&quot;</td>
                </tr>
                <tr>
                    <td>XL</td>
                    <td>60 – 61 cm</td>
                    <td>12,5&quot;</td>
                </tr>
            </table>
                </div>
            </div>
        </>
    );
}
export default TableSize
