import {fetcher} from "@framework/fetcher";
import Cookies from "js-cookie";
import {CUSTOMER_COOKIE} from "@framework/const";

const getOrders = async (id: string, locale: string, auth?: string) => {
    const token = auth ? auth : Cookies.get(CUSTOMER_COOKIE)
    return await fetcher({
        url: `/orders/${id}?lang=${locale}`,
        method: 'GET',
        headers: {
            'authorization': 'Bearer ' + token
        }
    });
}
export default getOrders
