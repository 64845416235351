import React, {useEffect, useRef, useState} from "react";
import {Button, useUI} from "@components/ui";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import cn from 'classnames';
// import * as faceapi from "face-api.js";
import style from './hugo.module.css';
import useCustomer from "@framework/customer/use-customer";
import saveToTested from "@framework/api/endpoints/hugo/saveToTested";
import Image from "@components/common/Image";
import useTranslation from "next-translate/useTranslation";

const SAFARI_MOBILE_BOTTOM_MENU_HEIGHT = 70;
export default function HugoStep2({...props}) {

    const { t } = useTranslation('common');

    const { data: customer } = useCustomer()
    const { openSidebar, setSidebarView } = useUI()

    //refs
    const imgRef = useRef() as React.MutableRefObject<HTMLImageElement>
    const refPhoto = useRef() as React.MutableRefObject<HTMLImageElement>
    const refPhotoNav = useRef() as React.MutableRefObject<HTMLDivElement>

    // states
    const [imageSrc, setImageSrc] = useState<string|undefined>(undefined);
    const [getScale, setScale] = useState<number>(0.5);
    const [getDragging, setDragging] = useState<boolean>(false);
    const [getHatPosition, setHatPosition] = useState<{left: number, top: number}>({left: 0, top: 0});
    const [getImageRelPos, setImageRelPos] = useState<{x: number, y: number}>({ x: 0, y: 0 });
    const [getPhotoSelectorNavPosition, setPhotoSelectorNavPosition] = useState<number>(0);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    const setPhotoNavPosition = () => {
        if (document !== undefined) {

            const {bottom} = refPhotoNav.current.getBoundingClientRect();
            const offset = 0;
            if (Math.floor(bottom) > window.innerHeight) {
                setPhotoSelectorNavPosition(offset + SAFARI_MOBILE_BOTTOM_MENU_HEIGHT)
            } else {
                setPhotoSelectorNavPosition(offset)
            }
            /*const modalContentSelector = document.getElementsByClassName('modal-content');
            const photoNavSelector = document.getElementsByClassName('photo-selector-big-nav');
            if(modalContentSelector.length > 0 && photoNavSelector.length > 0) {
                const modalContent = modalContentSelector[0];
                const modalContentHeight = modalContent.clientHeight;

                const photoNav = photoNavSelector[0];
                const photoNavHeight = photoNav.clientHeight;
                //alert(modalContentHeight-photoNavHeight)
                //setPhotoSelectorNavPosition(Math.abs(modalContentHeight-photoNavHeight-70))
            }*/
        }
    }
   useEffect(() => {
     setImageSrc(props.image.image);
     setPhotoNavPosition();
   }, [props.image]);

    // effects
    useEffect(() => {

        //window.addEventListener('resize', setPhotoNavPosition);
        //window.addEventListener("touchstart", handleMouseDown)
        window.addEventListener('touchmove', handleMouseMove, { passive: false })
        window.addEventListener('touchend', handleMouseUp, { passive: false })

        window.addEventListener('mousemove', handleMouseMove, { passive: false })
        window.addEventListener('mouseup', handleMouseUp, { passive: false })

        return () => {
            window.removeEventListener("touchstart", handleMouseDown)
            window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('touchend', handleMouseUp)

            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
        };
    }, [getDragging])

    useEffect(() => {
        setIsSaved(false)
    }, [getScale, getHatPosition])

    const getPhotoSize = () => {
        return {
            w: refPhoto?.current?.width,
            h: refPhoto?.current?.height,
        }
    }

    // handlers
    const handleMouseDown = (event: any) => {

        const eventType = event.type.toLowerCase();

        if (eventType === 'touchstart') {
            setImageRelPos({
                x: event.changedTouches[0].pageX - getHatPosition.left,
                y: event.changedTouches[0].pageY - getHatPosition.top
            })
        } else if (eventType === 'mousedown') {
            setImageRelPos({
                x: event.pageX - getHatPosition.left,
                y: event.pageY - getHatPosition.top
            })

            event.stopPropagation()
            event.preventDefault()

        }

        setDragging(true)
    }
    const handleMouseUp = (event: any) => {
        setDragging(false)
        const eventType = event.type.toLowerCase();
        if (eventType === 'mouseup') {
            event.stopPropagation()
            event.preventDefault()
        }
    }
    const handleMouseMove = (event: any)  => {
        if(getDragging) {
            let newLeft = 0;
            let newTop = 0;
            const eventType = event.type.toLowerCase();
            if (eventType !== 'mousemove') {
                newLeft = event.changedTouches[0].pageX - getImageRelPos.x;
                newTop = event.changedTouches[0].pageY - getImageRelPos.y;
            } else if (eventType === 'mousemove') {
                newLeft = event.pageX - getImageRelPos.x;
                newTop = event.pageY - getImageRelPos.y;
            }

            const leftSide = -(getPhotoSize().w-imgRef.current.width*getScale)/2;
            const rightSide = (getPhotoSize().w-imgRef.current.width*getScale)/2;

            // těch 25px je polovina bílého horního okraje
            const topSide = -((imgRef.current.height-imgRef.current.height*getScale)/2); //-(832-imgRef.current.height*getScale)/2-25;
            const bottomSide = getPhotoSize().h-imgRef.current.height*getScale/2;//-(imgRef.current.height*getScale)+topSide //(832-imgRef.current.height*getScale)/2-25;

           // alert(bottomSide)
            if (newLeft <= leftSide) {
                newLeft = leftSide;
            }

            if (newLeft >= rightSide) {
                newLeft = rightSide;
            }

            if (newTop <= topSide) {
               newTop = topSide;
            }

            if (newTop >= bottomSide) {
               newTop = bottomSide;
            }

            setHatPosition({
                left: newLeft,
                top: newTop,
            })
        }
        event.stopPropagation()
        event.stopImmediatePropagation();
        event.preventDefault()
    }
    const handleScale = (scale: number) => {
            setScale(scale)

            let newLeft = getHatPosition.left;
            let newTop =  getHatPosition.top;

            const leftSide = -(getPhotoSize().w-imgRef.current.width*scale)/2;
            const rightSide = (getPhotoSize().w-imgRef.current.width*scale)/2;

            // těch 25px je polovina bílého horního okraje
            const topSide = -((imgRef.current.height-imgRef.current.height*scale)/2); //-(832-imgRef.current.height*getScale)/2-25;
            const bottomSide = getPhotoSize().h-(imgRef.current.height*scale)+topSide; //(832-imgRef.current.height*getScale)/2-25;

        if (newLeft <= leftSide) {
                newLeft = leftSide;
            }

            if (newLeft >= rightSide) {
                newLeft = rightSide;
            }

            if (newTop <= topSide) {
                newTop = topSide;
            }

            if (newTop >= bottomSide) {
                newTop = bottomSide;
            }

            setHatPosition({
                left: newLeft,
                top: newTop,
            })
        }
    const handleSelectPhoto = (e: any) => {
        e.preventDefault();
        if (props.onSelectPhoto) {
            props.onSelectPhoto()
        }
    }
    const handleOpenCamera = (e: any) => {
        e.preventDefault();
        if (props.onOpenCamera) {
            props.onOpenCamera()
        }
    }
    const handleSave = async (e: any) => {
        e.preventDefault()
        if(!customer) {
            setSidebarView('LOGIN_VIEW')
            openSidebar()
            return;
        }

        setIsSaving(true);

        const data = imageSrc?.substr(imageSrc.indexOf(',') + 1).trim()
        const leftSide = -(getPhotoSize().w-imgRef.current.width*getScale)/2;
        const topSide = -((imgRef.current.height-imgRef.current.height*getScale)/2); //-(832-imgRef.current.height*getScale)/2-25;

        await saveToTested(
            props.productId,
            data!,
            props.hatImage,
            imgRef.current.width*getScale * (refPhoto.current.naturalWidth/(refPhoto.current.width)),
            (imgRef.current.height*getScale) * (refPhoto.current.naturalWidth/(refPhoto.current.width)),
            ((getHatPosition.top-topSide) * (refPhoto.current.naturalHeight/(refPhoto.current.height))),
            ((getHatPosition.left-leftSide) * (refPhoto.current.naturalWidth/(refPhoto.current.width))),
            //(getHatPosition.left-leftSide)+25// * (refPhoto.current.naturalWidth/(refPhoto.current.width))/2)),
            //getHatPosition.left-leftSide, // 25 px je polovina z marginu
        )

        setIsSaving(false);
        setIsSaved(true);
    }

    /*
    const { data: customer } = useCustomer()
    const { openSidebar, setSidebarView } = useUI()

    //refs
    const imgRef = useRef() as React.MutableRefObject<HTMLImageElement>
    const refPhoto = useRef() as React.MutableRefObject<HTMLImageElement>

    // states
    const [imageSrc, setImageSrc] = useState<string|undefined>(undefined);
    const [getScale, setScale] = useState<number>(0.5);
    const [getDragging, setDragging] = useState<boolean>(false);
    const [getHatPosition, setHatPosition] = useState<{left: number, top: number}>({left: 0, top: 0});
    const [getImageRelPos, setImageRelPos] = useState<{x: number, y: number}>({ x: 0, y: 0 });

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(() => {
        setImageSrc(props.image.image);
    }, [props.image]);

    // effects
    useEffect(() => {

        // window.addEventListener("touchstart", handleMouseDown)
        window.addEventListener('mousemove', handleMouseMove)
        // window.addEventListener('touchmove', handleMouseMove)
        window.addEventListener('mouseup', handleMouseUp)

        return () => {
            // window.removeEventListener("touchstart", handleMouseDown)

            window.removeEventListener('mousemove', handleMouseMove)
            // window.removeEventListener('touchmove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
        };
    }, [getDragging])

    useEffect(() => {
        setIsSaved(false)
    }, [getScale, getHatPosition])


    // handlers
    const handleMouseDown = (event: any) => {
        setImageRelPos({
            x: event.pageX - getHatPosition.left,
            y: event.pageY - getHatPosition.top
        })
        setDragging(true)
        event.stopPropagation()
        event.preventDefault()
    }
    const handleMouseUp = (event: any) => {
        setDragging(false)
        event.stopPropagation()
        event.preventDefault()
    }
    const handleMouseMove = (event: any)  => {
        if(getDragging) {
            let newLeft = event.pageX - getImageRelPos.x;
            let newTop =  event.pageY - getImageRelPos.y;

            const leftSide = -(772-imgRef.current.width*getScale)/2;
            const rightSide = (772-imgRef.current.width*getScale)/2;

            // těch 25px je polovina bílého horního okraje
            const topSide = -((imgRef.current.height-imgRef.current.height*getScale)/2); //-(832-imgRef.current.height*getScale)/2-25;
            const bottomSide = 832-(imgRef.current.height*getScale)+topSide //(832-imgRef.current.height*getScale)/2-25;

            if (newLeft <= leftSide) {
                newLeft = leftSide;
            }

            if (newLeft >= rightSide) {
                newLeft = rightSide;
            }

            if (newTop <= topSide) {
                newTop = topSide;
            }

            if (newTop >= bottomSide) {
                newTop = bottomSide;
            }

            setHatPosition({
                left: newLeft,
                top: newTop,
            })
        }
        event.stopPropagation()
        event.preventDefault()
    }
    const handleScale = (scale: number) => {
        setScale(scale)

        let newLeft = getHatPosition.left;
        let newTop =  getHatPosition.top;

        const leftSide = -(772-imgRef.current.width*scale)/2;
        const rightSide = (772-imgRef.current.width*scale)/2;

        // těch 25px je polovina bílého horního okraje
        const topSide = -((imgRef.current.height-imgRef.current.height*scale)/2); //-(832-imgRef.current.height*getScale)/2-25;
        const bottomSide = 832-(imgRef.current.height*scale)+topSide; //(832-imgRef.current.height*getScale)/2-25;

        if (newLeft <= leftSide) {
            newLeft = leftSide;
        }

        if (newLeft >= rightSide) {
            newLeft = rightSide;
        }

        if (newTop <= topSide) {
            newTop = topSide;
        }

        if (newTop >= bottomSide) {
            newTop = bottomSide;
        }

        setHatPosition({
            left: newLeft,
            top: newTop,
        })
    }

    const handleSelectPhoto = (e: any) => {
        e.preventDefault();
        if (props.onSelectPhoto) {
            props.onSelectPhoto()
        }
    }

    const handleOpenCamera = (e: any) => {
        e.preventDefault();
        if (props.onOpenCamera) {
            props.onOpenCamera()
        }
    }

    const handleSave = async (e: any) => {
        e.preventDefault()
        if(!customer) {
            setSidebarView('LOGIN_VIEW')
            openSidebar()
            return;
        }

        setIsSaving(true);

        const data = imageSrc?.substr(imageSrc.indexOf(',') + 1).trim()
        const leftSide = -(772-imgRef.current.width*getScale)/2;
        const topSide = -((imgRef.current.height-imgRef.current.height*getScale)/2); //-(832-imgRef.current.height*getScale)/2-25;

        await saveToTested(
            props.productId,
            data!,
            props.hatImage,
            imgRef.current.width*getScale,
            imgRef.current.height*getScale,
            getHatPosition.top-topSide,
            getHatPosition.left-leftSide+25, // 25 px je polovina z marginu
        )
        setIsSaving(false);
        setIsSaved(true);
    }*/


        return <>
            <div className="modal-content" style={{position: 'absolute'}}>
                <div className="photo-selector-big">
                    <div className="photo-selector-big-main">

                        <div style={{overflow: 'hidden', position: 'relative', margin: '5rem'}}>
                            {/*<img src={imageSrc} style={{marginLeft: '-2.5rem'}} />*/}
                            <img src={imageSrc} ref={refPhoto} style={{width: '100%'}} />

                        {/*<div style={{overflow: 'hidden', position: 'relative', width: '760px', height: '860px', margin: '50px 50px 0px 50px'}}>
                                <img src={imageSrc}
                                     style={{display: 'block', position: 'absolute', top: 0, left: 0, transform: `translate3d(${props.image.position.left}px,${props.image.position.top+(444/2)}px, 0px) scale(${props.image.scale})`}} />
                            </div>*/}

                            <div className="photo-selector-hat" style={{top: 0, height: '100%'}}>
                                <img src={props.hatImage?.replace("/original/", '/555x543/')} ref={imgRef}  onTouchStart={handleMouseDown} onMouseDown={handleMouseDown}
                                     style={{height: 'auto', transform: `translate3d(${getHatPosition.left}px, ${getHatPosition.top}px, 0px) scale(${getScale})`, width: '100%',}} />
                            </div>
                        </div>


                            <div className="size-range-dark-wrap rc-slider-small">
                                {/*<div id="size-range-dark"
                                     className="ui-slider ui-slider-vertical ui-widget ui-widget-content ui-corner-all">
                                </div>*/}
                                <Slider min={0.2} max={1} value={getScale} step={0.0001} onChange={handleScale} vertical={true} />
                            </div>
                    </div>
                </div>
                <div className="photo-selector-big-nav" ref={refPhotoNav} style={{ bottom: getPhotoSelectorNavPosition}}>
                    <a href="#" onClick={handleSelectPhoto} className="photo-selector-select">{t("Vybrat foto")}</a>
                    <a href="#" onClick={handleOpenCamera} className="photo-selector-take">{t("Vyfotit")}</a>

                    <Button
                        className={"btn btn-background"}
                        loading={isSaving}
                        disabled={isSaving}
                        Component={'a'}
                        href={"#"}
                        style={{minWidth: 322}}
                        onClick={handleSave}
                    >
                        {isSaved && <>{t("Uloženo do vyzkoušených")}</>}
                        {!isSaved && <>{t("Uložit do vyzkoušených")}</>}
                    </Button>
                </div>
            </div>
        </>
}
