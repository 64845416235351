export const selectFirstVariant = (variants: any[], query: any = null): any => {
    let index = 0;

    if(query !== null && query.variant !== undefined) {
        if(!Array.isArray(query.variant)) {
            query.variant = [query.variant];
        }

        const qv = query.variant.map((v: any) => v.split('_')[1]).filter((v: any) => v !== undefined);


        let queryHasSize = false;
        if (query.variant !== undefined) {
            let _v = [];
            if (typeof query.variant === 'string') {
                _v.push(query.variant.split('_')[0]);
            } else {
                _v = query.variant?.map((_: any) => _.split('_')[0]);
            }

            queryHasSize = _v.indexOf('f75157e7-d28e-4156-bbf7-f7c5d4ccc297') > -1;
        }


        if(qv.length === 2) {
            // d239e9aa-601c-4cc0-9ef7-af27c19b4c26 - color
            // f75157e7-d28e-4156-bbf7-f7c5d4ccc297 - velikost
            variants.map((variant: any, i: number) => {
                const color = variant.params.filter((param: any) => param.param.id === 'd239e9aa-601c-4cc0-9ef7-af27c19b4c26')[0];
                const size = variant.params.filter((param: any) => param.param.id === 'f75157e7-d28e-4156-bbf7-f7c5d4ccc297')[0];

                if (qv.indexOf(color.value.group) > -1 && qv.indexOf(size.value.id) > -1) {
                    index = i
                }
            })
        } else if (queryHasSize) {
            variants.map((variant: any, i: number) => {
                const size = variant.params.filter((param: any) => param.param.id === 'f75157e7-d28e-4156-bbf7-f7c5d4ccc297')[0];

                if (qv.indexOf(size.value.id) > -1) {
                    index = i
                }
            })
        } else {
            variants.map((variant: any, i: number) => { console.log(variant.params)
                variant.params.forEach((param: any) => {
                    if (qv.indexOf(param.value.value) > -1 || qv.indexOf(param.value.group) > -1) {
                        index = i;
                    }
                })
            })
        }
    }

    return (variants?.length > 0) ? variants[index] : [];
}

export const hasVariantDifferentPrice = (variants: any[]): any => {
    let price: number = variants[0].actionPrice.amount ? variants[0].actionPrice.amount : variants[0].price.amount;
    for (let i = 1; i < variants?.length; i++) {
        const vPrice = variants[i].actionPrice.amount ? variants[i].actionPrice.amount : variants[i].price.amount;
        if(vPrice != price) {
            return true
        }
    }

    return false;
}
