import {FC, ReactElement, useEffect, useRef, useState} from 'react';
import Head from 'next/head';
import Link from 'next/link'
import {getCategoryPath, getPathByType} from "@lib/links";
import cn from 'classnames';
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";



interface Link {
    id: string
    slug: string
    name: string
}

interface NavbarProps {
    links?: Link[]
}

const Navbar: FC<NavbarProps> = ({ links }) => {
    const { t } = useTranslation('common');

    const [openBurger, setOpenBurger] = useState<boolean>(false)
    const [openSubNav, setOpenSubnav] = useState<number | null>(null)
    const router = useRouter();


    useEffect(() => {
        setOpenBurger(false);
        setOpenSubnav(null);
    }, [router])

    const handleOpenSubNav = (index: number) => {
        if (openSubNav === index) {
            setOpenSubnav(null);
        } else {
            setOpenSubnav(index);
        }
    }


    return (
        <>
        <nav role="Navigation" aria-label="Kategorie produktů">
            <h2 className="screenReaderElement">
                {t("Kategorie produktů")}
            </h2>
            <ul className={cn(["top-navigation", {['is-active']: openBurger}])}>
                {links?.map((item: any, index: number) => <li key={item.id + index} className={cn({['has-children']: item.children.length > 0 } )}>
                    {(router.locale == 'cs' || router.locale == 'en' && item.slug != 'gift-vouchers') && <Link href={getPathByType(item.slug, item.type)}><a>{item.name}</a></Link>}

                    {item.children.length > 0 && <ul className={cn({['is-active']: openSubNav === index})}>
                        {item.children?.map((child: any) => <li key={child.id}>
                            <Link href={getPathByType(child.slug, item.type)}><a>{child.name}</a></Link>
                        </li>)}
                    </ul>}

                    {item.children.length > 0 &&
                        <a href={"#"} onClick={(e) => { e.preventDefault(); handleOpenSubNav(index); }}
                           className={cn(["open-arrow", {['is-active']: openSubNav === index} ])} />}

                </li>)}
            </ul>
        </nav>
            <a href="#navigace"  onClick={(e) => { e.preventDefault(); setOpenBurger(!openBurger)}}
               className={cn(["burger",  {["is-active"]: openBurger}])}>
                <span className="burger-lines" />
                {t("Navigace")}
            </a>
        </>

    );
}
export default Navbar
