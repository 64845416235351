import React, { FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import useTranslation from "next-translate/useTranslation";

interface SidebarProps {
    children: any
    displaySidebar: boolean
    onClose: () => void
}

const Sidebar: FC<SidebarProps> = ({ children, onClose, displaySidebar }) => {
    const { t } = useTranslation('common');
    const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

    const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Escape') {
            onClose()
        }
    }

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if(onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (sidebarRef.current) {
            sidebarRef.current.focus()
        }

        const contentElement = contentRef.current

        if (contentElement && displaySidebar) {
            disableBodyScroll(contentElement, { reserveScrollBarGap: true })
        }

        return () => {
            clearAllBodyScrollLocks()
        }
    }, [displaySidebar])

    return (
        <div className={cn("sidebar", {"visible": displaySidebar})} ref={sidebarRef} tabIndex={1} onKeyDown={onKeyDownSidebar}>
            <div className="sidebar-inner" ref={contentRef}>
                {children}
            </div>
            <a href="#" onClick={handleClose} className="sidebar-close" title={t("Zavřít")} />
            <div className="sidebar-helper" onClick={handleClose} />
        </div>
    );
}

export default Sidebar
