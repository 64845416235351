import React, {FC, ReactElement, useEffect, useState} from 'react';
import Head from 'next/head';
import Link from 'next/link'
import Navbar from "@components/common/Navbar";
import Langbar from "@components/common/Langbar";
import Searchbar from "@components/common/Searchbar";
import {useUI} from "@components/ui/context";
import { Sidebar } from '@components/ui'
import {LoginView} from "@components/auth";
import Hugo from "@components/product/hugo";
import Modal from "@components/ui/Modal";
import cn from "classnames";
import {useAddItem, useCart, useRemoveItem} from '@framework/cart'
import {LineItem} from "@commerce/types/cart";
import useCustomer from "@framework/customer/use-customer";
import {useRouter} from "next/router";
import Filter from "@components/product/filter";
import InputNumber from "@components/ui/Input/number";
import {formatPrice} from "@lib/formatPrice";
import Image from "@components/common/Image";
import Newsletter from "@components/common/Newsletter";
import TableSize from "@components/product/tableSize";
import GiftModal from "@components/cart/GiftModal";
import HugoProducts from "@components/product/hugo/hugoProducts";
import ChangeVariant from "@components/product/changeVariant";
import {getPathByType} from "@lib/links";
import useTranslation from "next-translate/useTranslation";
import Price from "@components/common/Price";
import BuyVoucher from "@components/product/buyVoucher";
import BalikovnaModal from "@components/cart/GiftModal/BalikovnaModal";
import ReservationProduct from "@components/product/reservationProduct";
import Script from "next/script";
import {MaximumCapacity} from "@commerce/utils/errors";


// import useTranslation from 'next-translate/useTranslation';

export const siteTitle = 'Insurance App';


const ModalView: FC<{
    modalView: string;
    displayModal: boolean,
    closeModal(): any
    typeModal: string,
    options: any
}> = ({ modalView, closeModal, displayModal, options, typeModal}) => {
    return (
        <Modal onClose={closeModal} displayModal={displayModal} typeModal={((modalView === 'HUGO' || modalView === 'HUGO_PRODUCTS') ? 'modal-hugo' : typeModal)}>
            {modalView === 'HUGO' && <Hugo />}
            {modalView === 'HUGO_PRODUCTS' && <HugoProducts options={options} />}
            {modalView === 'NEWSLETTER' && <Newsletter />}
            {modalView === 'TABLE_SIZE' && <TableSize />}
            {modalView === 'GIFT' && <GiftModal />}
            {modalView === 'BUY_VOUCHER' && <BuyVoucher options={options} />}
            {modalView === 'CP_BALIKOVNA' && <BalikovnaModal />}
            {modalView === 'RESERVATION_PRODUCT' && <ReservationProduct />}
        </Modal>
    )
}

const ModalUI: FC<{options: any}> = ({options}) => {
    const { displayModal, closeModal, modalView, typeModal } = useUI()
    return (
        <ModalView modalView={modalView} displayModal={displayModal} options={options} typeModal={typeModal} closeModal={closeModal} />
    )
}

const SidebarView: FC<{
    sidebarView: string,
    displaySidebar: boolean,
    closeSidebar(): any
}> = ({ sidebarView, closeSidebar, displaySidebar }) => {
    return (
        <Sidebar onClose={closeSidebar} displaySidebar={displaySidebar}>
            {sidebarView === 'LOGIN_VIEW' && <LoginView />}
            {sidebarView === 'FILTER' && <Filter />}
            {sidebarView === 'CHANGE_VARIANT' && <ChangeVariant />}
        </Sidebar>
    )
}

const SidebarUI: FC = () => {
    const { displaySidebar, closeSidebar, sidebarView } = useUI()
    return (
        <SidebarView
            sidebarView={sidebarView}
            closeSidebar={closeSidebar}
            displaySidebar={displaySidebar}
        />
    )
}

const countItem = (count: number, item: LineItem) => count + parseInt(String(item.quantity))

const CountItems = () => {
    const { data } = useCart()
    const itemsCount = data?.items?.reduce(countItem, 0) ?? 0
    return <span>{itemsCount}</span>
}

const MiniCart = (props: any) => {
    const {t} = useTranslation("common")
    const addItem = useAddItem()
    const { data } = useCart()
    const deleteItem = useRemoveItem();
    const [maximumCapacityError, setMaximumCapacityError] = useState<{id: string, message: string, type: string} | null>(null);


    const updateCart = async (val: number, productId: string, variantId: string | null) => {
        try {
            await addItem({
                quantity: val,
                productId: productId,
                variantId: variantId,
            })
            setMaximumCapacityError(null);
        } catch (e) {
            if (e instanceof MaximumCapacity) {
                setMaximumCapacityError(null);
                setMaximumCapacityError({
                    id: productId + variantId ?? '',
                    message: t('Překročili jste maximální objednatelné množství'),
                    type: 'red',
                })
                console.log("chyba");
            } else {
                throw e;
            }
        }
    }

    const handleDelete = async (id: string, variantId?: string) => {
        deleteItem({ id, variantId: (variantId ? variantId : '') });
    }


    if (data === null || data?.items?.length === 0 || data?.items === undefined) {
        return <>
            <div className="orange-alert">
                {t("V košíku nic není")}
            </div>
            <div className="btns">
                <Link href={"/category"}>
                    <a className="btn">
                        {t("Přejít na produkty")}
                    </a>
                </Link>
            </div>
        </>
    }

    return  <>
        {data?.items?.map((item, index) => <div key={index} className="header-cart-item">
                                        <div className="header-cart-item-image">
                                            <Link href={`/product/${item.slug}`}>
                                                <a>
                                                    {item.variant && <Image src={item.variant.mainPhoto!} width={120} height={117} />}
                                                    {!item.variant && <Image src={item.mainPhoto} width={120} height={117} />}
                                                </a>
                                            </Link>
                                        </div>
                                        <div className="header-cart-item-content">
                                            <div className={"cart-content"}>
                                                <div className="hcic-title">
                                                    {item.name}
                                                </div>
                                                <p>
                                                {item.code && <>
                                                    {t("Kód produktu")}: {item.code} <br />
                                                </>}

                                                {(item.hasVariants && item.variant.code) && <>
                                                    {t("Kód produktu")}: {item.variant.code} <br />
                                                </>}
                                                {item.hasVariants && <>
                                                    {item.variant.params?.map((param) => <>{param.param.name}: {param.value.value}<br /></>
                                                    )}
                                                </>}
                                                </p>
                                            </div>
                                            <div className="hcic-price">
                                                {item.hasVariants && <><Price
                                                    amount={item.variant?.price.amount}
                                                    quantity={item.quantity}
                                                    vat={item?.variant.price?.vat}
                                                    amountWithoutVat={item?.variant?.price.amountWithoutVat}
                                                    actionPrice={ {
                                                        amount: item.variant?.actionPrice.amount,
                                                        amountWithoutVat: item.variant?.actionPrice.amountWithoutVat
                                                    }}
                                                    showOriginalIfExistDiscount={false}
                                                    priceOption={props.settings.currency} /></>}
                                                {!item.hasVariants && <Price
                                                    amount={item?.price.amount}
                                                    quantity={item.quantity}
                                                    amountWithoutVat={item?.price.amountWithoutVat}
                                                    actionPrice={ {
                                                        amount: item?.actionPrice.amount,
                                                        amountWithoutVat: item?.actionPrice.amountWithoutVat
                                                    }}
                                                    showOriginalIfExistDiscount={false}
                                                    vat={item?.price?.vat} priceOption={props.settings.currency} />}

                                                {/*item.variant && <>{formatPrice(parseInt(item.quantity.toString())*(item.variant.actionPrice ? item.variant.actionPrice : item.variant.price), props.settings.currency)}</>*/}
                                                {/*!item.variant && <>{formatPrice(parseInt(item.quantity.toString())*(item.actionPrice ? item.actionPrice : item.price), props.settings.currency)}</>*/}
                                            </div>

                                            <div style={{ display: "block", float: "none", clear: "both"}} />
                                            {(maximumCapacityError && maximumCapacityError.id === (item.id + item.variant?.id ?? '')) && <div className={`${maximumCapacityError.type}-alert`}>{maximumCapacityError.message}</div>}

                                            <InputNumber min={1} onChange={(val: number | null) => updateCart(val ? val : 1, item.id, item.variant?.id)} resetValue={(maximumCapacityError !== null && maximumCapacityError.id === (item.id + item.variant?.id ?? ''))} value={parseInt(item.quantity.toString())} />
                                            <a href="#" onClick={() => handleDelete(item.id, item.variant?.id) } className="hcic-trash" />
                                        </div>
                                    </div>)}
                                    <div className="header-cart-price">
                                        {t("Celkem")}
                                        <span>
                                            <Price
                                                amount={data?.totalPriceItems.amount}
                                                vat={0}
                                                recalculate={false}
                                                amountWithoutVat={data?.totalPriceItems.amountWithoutVat}
                                                showOriginalIfExistDiscount={false}
                                                priceOption={props.settings.currency} />
                                        </span>
                                    </div>
                                    <div className="btns">
                                        <Link href={"/category"}>
                                            <a className="btn">
                                                {t("Pokračovat v nákupu")}
                                            </a>
                                        </Link>
                                        <Link href={"/cart"}>
                                            <a className="btn btn-background">
                                                {t("Přejít k pokladně")}
                                            </a>
                                        </Link>
                                    </div>
                                    </>
}

interface ILayout {
    pageProps: {
        children: ReactElement;
        topNavLinks?: any[];
        bottomNavLinks?: any[];
        whiteFooter?: boolean;
        narrowFooter?:boolean;
        settings?: any
    }
}


const Layout: FC<ILayout> = ({
                               children,
                               pageProps: { topNavLinks = [], bottomNavLinks = [], ...pageProps },
                           }) => {
    const { t } = useTranslation('common');
    const { openSidebar, setSidebarView, openModal, setTypeModal, setModalView } = useUI()
    const [isLogged, setIsLogged] = useState<boolean>(false)
    const { data, isLoading, error } = useCustomer()
    const cart = useCart();
    const router = useRouter()

    useEffect(() => {
        if((data === null || data === undefined)  && isLoading === false) {
            setIsLogged(false);
        } else {
            setIsLogged(true);
        }
        cart.mutate();
    }, [data, isLoading, error])

    const handleOpenLogin = (e: any, link: string) => {
        e.preventDefault();
        e.stopPropagation();

        if(data) {
            router.push(link)
        } else {
            setSidebarView('LOGIN_VIEW');
            openSidebar(true)
        }
    }

    const openNewsletter = () => {
        setTypeModal("modal-gift");
        setModalView("NEWSLETTER");
        openModal(true);
    }

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width,initial-scale=1.0,user-scalable=no,maximum-scale=1" />
            </Head>
            <h1 className="screenReaderElement">TONAK</h1>
            <header className="header">
                <div className="header-top">
                    <div className="header-top-left">
                        <a href="tel:420556202111">
                            <img src="/images/ico-phone.svg" alt="Telefon"/>
                            {t("Potřebujete poradit?")} +420 556 202 111
                        </a>
                        <a href="#" onClick={openNewsletter}>
                            <img src="/images/ico-mail.svg" alt="Newsletter"/>
                            {t("Chci odebírat newsletter")}
                        </a>
                    </div>
                    <div className="header-top-right">
                        <Searchbar />
                        <Langbar slugs={{'cs': "muzi", 'en':'man'}} />
                        <div className="header-ico">
                            <a href="#" onClick={(e) => handleOpenLogin(e, '/my-account/favorites')} className="header-ico-favorite" title={t("Oblíbené")} />
                        </div>
                        <div className="header-ico">
                            <a href="#"
                               onClick={(e) => handleOpenLogin(e, '/my-account/')}
                               className={cn("header-ico-user open-sidebar", {['header-ico-user-fill']: isLogged})}
                               title={t("Uživatelský účet")} />
                        </div>
                        <div className="header-ico">
                            <Link href={"/cart"}>
                                <a className="header-ico-cart" title={t("Košík")}>
                                    <CountItems />
                                </a>
                            </Link>
                            <div className="header-cart">
                                <div className="header-cart-inner">
                                    <div className="header-cart-title">
                                        {t("Můj košík")}
                                    </div>

                                    <MiniCart settings={pageProps.settings} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-main">
                    <Link href={"/"}>
                        <a className="header-logo">
                            <img src="/images/logo.svg" alt="TONAK"/>
                        </a>
                    </Link>
                    <span className="header-burger"/>

                    <Navbar links={topNavLinks} />


                    <Link href={router.locale === 'cs' ? "/page/zodpovedna-vyroba" : '/en/page/responsible-production'}>
                        <a className="btn">
                            {t("Zodpovědná výroba")}
                        </a>
                    </Link>

                </div>
            </header>


            {children}


            <footer className={cn(["footer", {"footer-white": pageProps?.whiteFooter}, {'footer-narrow': pageProps?.narrowFooter}])}>
                <div className="footer-inner">
                    <Link href={"/"}>
                        <a className="footer-logo">
                            <img src="/images/logo.svg" alt="TONAK" />
                        </a>
                    </Link>

                    <ul>
                        {bottomNavLinks.map((link) => <li key={link.id}>
                            <Link href={getPathByType(link.slug, link.type)}>
                                <a>
                                    {link.name}
                                </a>
                            </Link>
                        </li>)}

                    </ul>
                    <div className="footer-social">
                        <a href="https://www.instagram.com/tonak.klobouky/" target={"_blank"}  rel="noreferrer" title="Instagram" className="fs-instagram" />
                        <a href="https://www.facebook.com/tonaknj/" target={"_blank"}   rel="noreferrer" title="Facebook" className="fs-facebook" />
                        <a href="https://www.linkedin.com/company/tonak-a-s-/" target={"_blank"}   rel="noreferrer" title="LinkedIn" className="fs-linkedin" />
                    </div>
                    <div className={"heureka"}>
                        <div id="showHeurekaBadgeHere-11" />
                        <Script id="show-banner" strategy="lazyOnload">
                            {`var _hwq = _hwq || [];
        _hwq.push(['setKey', 'EB356519771721C94D4B4141BDF56FF1']);
        _hwq.push(['setTopPos', '100']);
        _hwq.push(['showWidget', '21']);
        (function() {
            var ho = document.createElement('script');
            ho.src = 'https://cz.im9.cz/direct/i/gjs.php?n=wdgt&sak=EB356519771721C94D4B4141BDF56FF1';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);
        })();`}
                            {/*`var _hwq = _hwq || [];
    _hwq.push(['setKey', 'EB356519771721C94D4B4141BDF56FF1']);
    _hwq.push(['showWidget', '11', '68233', 'TONAK a.s.', 'tonak-as']);(function() {
    var ho = document.createElement('script'); ho.type = 'text/javascript'; ho.async = true;
    ho.src = 'https://www.heureka.cz/direct/i/gjs.php?n=wdgt&sak=EB356519771721C94D4B4141BDF56FF1';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);
})();`*/}
                        </Script>
                    </div>
                    <div className="footer-copyright">
                        Copyright © 2021 TONAK.cz <span /> braincoded by <a href="https://www.frontio.net"
                                                                                 target="_blank" rel="noreferrer">frontio</a>
                        <span /> powered by <a href="https://www.storevio.cz"
                                    target="_blank" rel="noreferrer">Storevio</a>
                    </div>
                </div>
            </footer>

            <ModalUI options={pageProps.settings} />
            <SidebarUI />
        </>

    );
}

export default Layout
