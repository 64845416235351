export const getCategoryPath = (name: string) => {
    return `/category/${name}`
}

export const getProductPath = (name: string) => {
    return `/product/${name}`
}

export const getPagePath = (name: string) => {
    return `/page/${name}`
}

export const getPathByType = (slug: string, type: string) => {
    if (type === 'CATEGORY') {
        return getCategoryPath(slug);
    }
    if (type === 'PAGE') {
        return getPagePath(slug);
    }
    return `#unknow-${slug}`
}
