import React, {FC, useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

interface SidebarProps {
    value?: number
    min?: number
    max?: number,
    resetValue?: boolean,
    onChange?: (value: number | null) => void
}

const InputNumber: FC<SidebarProps> = ({ onChange, value= null, min = null, max = null, resetValue = false }) => {
    const [getValue, setValue] = useState<number | null>(0)

    useEffect(() => {
        setValue(value);
        console.log("valu")
        console.log(value);
    }, [value, resetValue])

    const handleIncrementValue = (e: any) => {
        e.preventDefault()
        handleChange((getValue === null ? 1 : getValue+1));
    }

    const handleDecrementValue = (e: any) => {
        e.preventDefault()
        handleChange((getValue === null ? 1 : getValue-1));
    }

    const handleChange = (value: number | null) => {
        if(min && value != null && value < min) return;
        if(max && value != null && value > max) return;

        if(onChange) {
            onChange(value)
        }
        setValue(value)
    }

    return (
        <div className="items-count">
            <input type="number" value={getValue !== null ? getValue.toString() : ""}
                   min={min?.toString()}
                   max={max?.toString()}
                   onChange={(e) => handleChange(e.target.value ? parseInt(e.target.value) : null)} />
            <span className="items-count-plus" onClick={handleIncrementValue} />
            <span className="items-count-minus" onClick={handleDecrementValue} />
        </div>
    );
}

export default InputNumber
