import React, { FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import useTranslation from "next-translate/useTranslation";

interface ModalProps {
    children: any
    displayModal: boolean
    onClose: () => void
    typeModal: string
}

const Modal: FC<ModalProps> = ({ children, onClose, displayModal, typeModal }) => {
    const { t } = useTranslation('common');
    const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

    const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Escape') {
            onClose()
        }
    }

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if(onClose) {
            onClose();
        }
    };




    useEffect(() => {
        const contentElement = contentRef.current;
        if (contentElement) {
            window.addEventListener("resize", () => {
                if (contentElement.clientHeight >= window.innerHeight) {
                    contentElement.style.height = "100%";
                } else {
                    contentElement.style.height = "auto";
                }
            });
        }
        return () => {
            window.removeEventListener("resize", () => {
                if (contentElement.clientHeight >= window.innerHeight) {
                    contentElement.style.height = "100%";
                } else {
                    contentElement.style.height = "auto";
                }
            })
        }
    }, []);

    useEffect(() => {
        if (sidebarRef.current) {
            sidebarRef.current.focus()
        }

        const contentElement = contentRef.current
        contentElement.style.height = "auto";

        if (contentElement && displayModal) {
            new ResizeObserver(function () {
                console.log(contentElement.clientHeight)
                if (displayModal && contentElement.clientHeight >= window.innerHeight) {
                    contentElement.style.height = "100%";
                } else {
                    contentElement.style.height = "auto";
                }
            }).observe(contentElement);

            if (displayModal && contentElement.clientHeight >= window.innerHeight) {
                contentElement.style.height = "100%";
            } else {
                contentElement.style.height = "auto";
            }

            disableBodyScroll(contentElement, { reserveScrollBarGap: true })
        }

        return () => {
            clearAllBodyScrollLocks()
        }
    }, [displayModal])

    return (
        <div className={"modals"}>
            <div className={cn("modal", typeModal , {"visible": displayModal})} ref={sidebarRef} tabIndex={1} onKeyDown={onKeyDownSidebar}>
                <div className="modal-content" ref={contentRef} style={{height: 'auto'}}>
                    {typeModal !== 'modal-hugo' && <a href="#" onClick={handleClose} className="modal-close" title={t("Zavřít")} />}
                    {children}
                </div>
                {typeModal === 'modal-hugo' && <a href="#" onClick={handleClose} className="modal-close" title={t("Zavřít")} />}
            <div className="modal-helper" onClick={handleClose} />
            </div>
        </div>
    );
}

export default Modal
